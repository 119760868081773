import { ThemeToggle } from './ThemeToggle';
import { formatTimeAgo } from '@/lib/utils';
import { useEffect, useRef, useState } from 'react';
import { cx } from 'class-variance-authority';
import { EditableToggle } from './EditableToggle';
import { PageActions } from './PageActions';
import ArchivedIndicator from './ArchivedIndicator';
import { SyncStatus } from './SyncStatus';
import { usePageStore } from '@/stores/usePageStore';

const Menu = () => {
  const currentPage = usePageStore(state => state.currentPage);
  const [showTitle, setShowTitle] = useState(false);
  const lastScrollTop = useRef(0);
  const menuRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!menuRef.current) return;

      const scrollContainer = menuRef.current.closest('.overflow-y-auto');
      if (!scrollContainer) return;

      const scrollTop = scrollContainer.scrollTop;
      if (scrollTop > lastScrollTop.current && scrollTop > 60) {
        setShowTitle(true);
      } else if (scrollTop <= 60) {
        setShowTitle(false);
      }
      lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
    };

    const scrollContainer = menuRef.current?.closest('.overflow-y-auto');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll, { passive: true });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <nav ref={menuRef} className="sticky z-50 top-0 w-full bg-background">
      <div className="flex items-center justify-between p-2 pr-3 w-full">
        <div className="ml-10 flex gap-1 items-center">
          <div
            className={cx(
              'truncate text-md font-medium transition-opacity duration-300 ease-in-out',
              'max-w-[150px] sm:max-w-[250px] md:max-w-none overflow-hidden font-semibold',
              showTitle ? 'block' : 'hidden'
            )}
          >
            {currentPage?.title} {currentPage?.archivedAt ? '(Archived)' : ''}
          </div>
        </div>
        <div className="flex items-center">
          {currentPage && (
            <div className="hidden md:block text-muted-foreground text-sm mr-2 truncate">
              Edited {formatTimeAgo(currentPage.updatedAt)}
            </div>
          )}
          <EditableToggle />
          <ThemeToggle />
          <SyncStatus />
          {currentPage && (
            <div className="h-[30px] w-[30px] ml-2">
              <PageActions />
            </div>
          )}
        </div>
      </div>
      <ArchivedIndicator />
    </nav>
  );
};

export default Menu;
