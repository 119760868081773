import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, FolderOpen } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import CollectionCard from '@/components/Collection/Card';
import CollectionBanner from '@/components/Collection/Banner';
import { useCollectionStore } from '@/stores/useCollectionStore';
import { useCollections } from '@/hooks/useCollections';
import { usePages } from '@/hooks/usePages';

const CollectionsPage = () => {
  const navigate = useNavigate();
  const { setCurrentPageId } = usePages();
  const collections = useCollectionStore(state => state.collections);
  const { addCollection, searchCollections, loadCollectionStructure } = useCollections();
  const [searchQuery, setSearchQuery] = useState('');
  const showWelcome = useMemo(
    () => !searchQuery && collections.filter(c => !c.isSample).length === 0,
    [collections, searchQuery]
  );

  useEffect(() => {
    return () => {
      void searchCollections('');
      void setCurrentPageId(null);
      void loadCollectionStructure();
    };
  }, []);

  const filteredCollections = collections.filter(collection =>
    collection.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const createCollection = useCallback(async () => {
    const coll = await addCollection();
    navigate(`/collection/${coll.id}`);
  }, [addCollection, navigate]);

  return (
    <div className="mx-auto md:max-w-3xl lg:max-w-6xl px-8 mb-12">
      <CollectionBanner />

      <div className="mx-auto md:max-w-3xl lg:max-w-4xl py-4 mt-4">
        <CollectionActions
          showWelcome={showWelcome}
          searchQuery={searchQuery}
          setSearchQuery={query => {
            setSearchQuery(query);
            void searchCollections(query);
          }}
          addCollection={createCollection}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          {filteredCollections.map(collection => (
            <CollectionCard key={collection.id} collection={collection} />
          ))}
        </div>

        {filteredCollections.length === 0 && <NoCollections searchQuery={searchQuery} />}
      </div>
    </div>
  );
};

export default CollectionsPage;

const CollectionActions = ({
  showWelcome,
  searchQuery,
  setSearchQuery,
  addCollection,
}: {
  showWelcome: boolean;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  addCollection: () => void;
}) => {
  if (!showWelcome) {
    return (
      <div className="flex justify-between items-center mb-6">
        <Input
          placeholder="Search collections..."
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value);
          }}
          className="w-full max-w-xs"
        />
        <Button onClick={addCollection} className="flex items-center gap-2 bg-primary">
          <Plus className="h-4 w-4" />
          New Collection
        </Button>
      </div>
    );
  }
  return (
    <div className="mb-6">
      <Button
        size="lg"
        className="flex items-center gap-2 bg-primary"
        onClick={() => {
          addCollection();
        }}
      >
        <Plus className="mr-2 h-4 w-4" />
        Start Your First Collection
      </Button>
    </div>
  );
};

const NoCollections = ({ searchQuery }: { searchQuery: string }) => {
  return (
    <div className="text-center py-12">
      <FolderOpen className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
      <h3 className="text-lg font-medium mb-2">No collections found</h3>
      <p className="text-muted-foreground">
        {searchQuery
          ? 'Try a different search term'
          : 'Create your first collection to get started'}
      </p>
    </div>
  );
};
