import { Page } from '@/types';
import {
  collection,
  doc,
  query,
  where,
  getDocs,
  writeBatch,
  DocumentReference,
  setDoc,
  serverTimestamp,
  getDoc,
  WriteBatch,
} from 'firebase/firestore';
import { auth, db } from './firebase';
import { signInWithPopup, GoogleAuthProvider, User } from 'firebase/auth';

export class AuthService {
  private readonly maxRetries = 3;
  private readonly retryDelay = 1000; // 1 second
  private userId: string | null = null;

  constructor() {
    auth.onAuthStateChanged(user => {
      this.userId = user?.uid || null;
    });
  }

  private async ensureUserDocumentExists(userId: string): Promise<void> {
    const userDocRef = doc(db, `users/${userId}`);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      // Create initial user document
      await setDoc(userDocRef, {
        email: auth.currentUser?.email,
        createdAt: serverTimestamp(),
        lastActive: serverTimestamp(),
      });
    }
  }

  private ensureAuthenticated() {
    if (!this.userId || !auth.currentUser) {
      throw new Error('User must be authenticated to perform this operation');
    }
    return this.userId;
  }

  async signIn(): Promise<User> {
    const provider = new GoogleAuthProvider();
    // Add additional scopes if needed
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');

    const result = await signInWithPopup(auth, provider);

    // Initialize user document if it doesn't exist
    await setDoc(
      doc(db, `users/${result.user.uid}`),
      {
        email: result.user.email,
        lastActive: serverTimestamp(),
      },
      { merge: true }
    );

    this.userId = result.user.uid;

    await this.ensureUserDocumentExists(result.user.uid);

    return result.user;
  }

  async signOut(): Promise<void> {
    await auth.signOut();
  }

  getCurrentUser(): User | null {
    return auth.currentUser;
  }

  onAuthStateChanged(callback: (user: User | null) => void): () => void {
    return auth.onAuthStateChanged(callback);
  }

  private async executeBatchWithRetry(
    operation: () => Promise<void>,
    retryCount = 0
  ): Promise<void> {
    try {
      await operation();
    } catch (error) {
      console.error(`Batch operation failed (attempt ${String(retryCount + 1)}):`, error);
      if (retryCount < this.maxRetries) {
        await new Promise(resolve => setTimeout(resolve, this.retryDelay * (retryCount + 1)));
        return this.executeBatchWithRetry(operation, retryCount + 1);
      }
      throw error;
    }
  }

  private async processBatchOperations(
    userId: string,
    notes: Page[],
    operation: (batch: WriteBatch, ref: DocumentReference, note: Page) => void
  ): Promise<void> {
    const BATCH_SIZE = 500; // Firestore limit

    // Split notes into chunks
    const chunks = Array.from({ length: Math.ceil(notes.length / BATCH_SIZE) }, (_, i) =>
      notes.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE)
    );

    for (const chunk of chunks) {
      await this.executeBatchWithRetry(async () => {
        const batch = writeBatch(db);

        for (const note of chunk) {
          const noteRef = doc(db, `users/${userId}/notes/${note.id}`);
          operation(batch, noteRef, note); // Pass the note to the operation
        }

        await batch.commit();
      });
    }
  }

  async bulkAddNotes(notes: Page[]): Promise<void> {
    const userId = this.ensureAuthenticated();
    if (!notes.length) return;

    await this.processBatchOperations(userId, notes, (batch, noteRef, note) => {
      // Include note parameter
      batch.set(noteRef, {
        ...note,
      });
    });
  }

  async bulkUpdateNotes(notes: Page[]): Promise<void> {
    const userId = this.ensureAuthenticated();
    if (!notes.length) return;

    await this.processBatchOperations(userId, notes, (batch, noteRef, note) => {
      // Include note parameter
      batch.update(noteRef, {
        ...note,
      });
    });
  }

  async bulkDeleteNotes(notes: Page[]): Promise<void> {
    const userId = this.ensureAuthenticated();
    if (!notes.length) return;

    await this.processBatchOperations(userId, notes, (batch, noteRef) => {
      batch.delete(noteRef);
    });
  }

  async getChangesSince(lastSyncTime: number | null): Promise<Page[]> {
    const userId = this.ensureAuthenticated(); // Make sure to await this

    try {
      const notesRef = collection(db, `users/${userId}/notes`);
      const q = lastSyncTime
        ? query(notesRef, where('updatedAt', '>', lastSyncTime))
        : query(notesRef);

      const querySnapshot = await getDocs(q);

      return querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id, // Ensure ID is included
        } as Page;
      });
    } catch (error) {
      console.error('Error fetching changes:', error);
      throw error;
    }
  }
}
