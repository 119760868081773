import { memo } from 'react';
import SidebarWrapper from '@/components/MainSidebar/SidebarWrapper';
import { Trash } from '@/components/MainSidebar/SidebarFooter';
import { Collection } from '@/types';
import CollectionPageList from './CollectionPageList';
import CollectionSearchButton from './Search';
import Header from './SidebarHeader';
import SectionListHeader from './SectionListHeader';

const CollectionSidebar = ({
  collection,
  onBackClick,
}: {
  collection: Collection;
  onBackClick: () => void;
}) => {
  return (
    <SidebarWrapper>
      <Header currentCollection={collection} onBackClick={onBackClick} />
      <div className="px-2">
        <CollectionSearchButton collectionId={collection.id} />
        <SectionListHeader />
      </div>
      <div className="flex-grow">
        <CollectionPageList />
      </div>
      <div className="pt-3 px-2 pb-2">
        <Trash collectionId={collection.id} />
      </div>
    </SidebarWrapper>
  );
};

export default memo(CollectionSidebar);
