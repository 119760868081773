import { Route, Routes } from 'react-router-dom';

import HomePage from '@/pages/HomePage';
import DocumentPage from '@/pages/DocumentPage';
import SharedPage from '@/pages/SharedPage';

import PrivacyPolicy from './pages/Privacy';
import MainLayout from './components/MainLayout';
import Menu from '@/components/MenuBar';

import CollectionsPage from './pages/CollectionsPage';
import CollectionDetailPage from './pages/CollectionDetailPage';
import CollectionLayout from './components/Collection/Layout';
import CollectionMenu from './components/Collection/Menu';

import { ProviderWrapper } from './providers';

export default function App() {
  return (
    <ProviderWrapper>
      <Routes>
        {/* Shared route without sidebar/menu */}
        <Route path="/shared/:pageId" element={<SharedPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Routes with sidebar/menu layout */}
        <Route
          path="/"
          element={
            <MainLayout Menu={<Menu />}>
              <HomePage />
            </MainLayout>
          }
        />
        <Route
          path="/collections"
          element={
            <MainLayout Menu={<CollectionMenu />}>
              <CollectionsPage />
            </MainLayout>
          }
        />

        <Route
          path="/collection/:collectionId"
          element={
            <CollectionLayout Menu={<Menu />}>
              <CollectionDetailPage />
            </CollectionLayout>
          }
        />
        <Route
          path="/collection/:collectionId/page/:pageId"
          element={
            <CollectionLayout Menu={<Menu />}>
              <CollectionDetailPage />
            </CollectionLayout>
          }
        />

        <Route
          path="/page/:pageId"
          element={
            <MainLayout Menu={<Menu />}>
              <DocumentPage />
            </MainLayout>
          }
        />
      </Routes>
    </ProviderWrapper>
  );
}
