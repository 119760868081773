import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Search, Trash, Undo } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { CustomModal } from '@/components/ui/modal';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { usePageStore } from '@/stores/usePageStore';
import { usePages } from '@/hooks/usePages';

const TrashBox = ({ collectionId }: { collectionId?: string }) => {
  const navigate = useNavigate();
  const archivedPages = usePageStore(state => state.archivedPages);
  const { getArchivedPages, unarchivePage, deletePage, searchArchivedPages } = usePages();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<{ id: string; title: string } | null>(null);

  useEffect(() => {
    const loadArchivedPages = async () => {
      await getArchivedPages(collectionId);
    };
    void loadArchivedPages();
  }, [collectionId, getArchivedPages]);

  const onRestore = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>, documentId: string) => {
      event.stopPropagation();
      await unarchivePage(documentId);
    },
    [unarchivePage]
  );

  const initiateDelete = (id: string, title: string) => {
    setToDelete({ id, title });
    setIsModalOpen(true);
  };

  const onRemove = useCallback(
    async (documentId: string) => {
      await deletePage(documentId);
      setToDelete(null);
      setIsModalOpen(false);
    },
    [deletePage, navigate]
  );

  return (
    <section className="text-sm mt-2 pb-2">
      <div className="flex items-center gap-x-1 p-2">
        <Search className="h-4 w-4" />
        <Input
          onChange={e => {
            void searchArchivedPages(e.target.value, collectionId);
          }}
          className="h-7 bg-secondary px-2 focus-visible:ring-transparent"
          placeholder="Filter by note title or content..."
          aria-label="Filter notes"
        />
      </div>
      <div className="mt-2 px-1 pb-1 max-h-[300px] overflow-y-auto">
        {archivedPages.length === 0 && (
          <p className="pb-2 text-center text-xs text-muted-foreground">No notes found.</p>
        )}
        {archivedPages.map(document => (
          <div
            key={document.id}
            className="flex w-full items-center justify-between rounded-sm hover:bg-muted"
          >
            <Link
              to={
                document.collectionId
                  ? `/collection/${document.collectionId}/page/${String(document.id)}`
                  : `/page/${String(document.id)}`
              }
              className="truncate pl-2 flex-grow"
            >
              {document.title}
            </Link>
            <div className="flex items-center">
              <button
                onClick={e => {
                  void onRestore(e, document.id);
                }}
                className="rounded-sm p-2 hover:bg-secondary"
                aria-label="Restore Document"
              >
                <Undo className="h-4 w-4 text-muted-foreground " />
              </button>
              <button
                className="rounded-sm p-2 hover:bg-secondary"
                aria-label="Delete Permanently"
                onClick={() => {
                  initiateDelete(document.id, document.title);
                }}
              >
                <Trash className="h-4 w-4 text-muted-foreground " />
              </button>
            </div>
          </div>
        ))}
      </div>
      {toDelete && (
        <CustomModal
          title="Confirm Delete"
          width={400}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        >
          <Card className="bg-background flex flex-col p-6 pb-4">
            <h2 className="text-center text-lg mb-4">
              Are you sure you want to delete the{' '}
              <span className="font-semibold">"{toDelete.title}"</span> notes permanently?
            </h2>
            <div className="space-y-2 flex flex-col">
              <Button
                onClick={() => {
                  void onRemove(toDelete.id);
                }}
                variant={'destructive'}
                className="border-destructive text-destructive-foreground hover:bg-destructive hover:text-destructive-foreground"
              >
                Yes, delete these notes
              </Button>
              <Button
                onClick={() => {
                  setIsModalOpen(false);
                }}
                variant={'ghost'}
              >
                Cancel
              </Button>
            </div>
          </Card>
        </CustomModal>
      )}
    </section>
  );
};

export default TrashBox;
