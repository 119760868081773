import { PageMetadata } from '@/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

type SearchFunction = (
  query: string,
  archived: boolean,
  collectionId?: string
) => Promise<PageMetadata[]>;
interface SearchHookConfig {
  searchFn: SearchFunction;
  defaultResults: PageMetadata[];
  collectionId?: string;
}

interface SearchHookResult {
  searchQuery: string;
  searchResults: PageMetadata[];
  isModalOpen: boolean;
  setSearchQuery: (query: string) => void;
  setIsModalOpen: (isOpen: boolean) => void;
  onClickPage: (pageId: string) => void;
}

export const useSearchPage = ({
  searchFn,
  defaultResults,
  collectionId,
}: SearchHookConfig): SearchHookResult => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<PageMetadata[]>(defaultResults);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        searchFn(searchQuery, false, collectionId)
          .then(setSearchResults)
          .catch(() => {
            setSearchResults([]);
          });
      } else {
        setSearchResults(defaultResults);
      }
    }, 100);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchQuery, searchFn, defaultResults, collectionId]);

  const onClickPage = (pageId: string, itemCollectionId?: string) => {
    navigate(
      itemCollectionId ? `/collection/${itemCollectionId}/page/${pageId}` : `/page/${pageId}`
    );
    setIsModalOpen(false);
  };

  return {
    searchQuery,
    searchResults,
    isModalOpen,
    setSearchQuery,
    setIsModalOpen,
    onClickPage,
  };
};
