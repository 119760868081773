import { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash-es';
import Editor from '@/components/Editor';
import PageTitle from '@/components/PageTitle';
import { useSaveHook } from '@/hooks/useSaveHook';
import { useSyncContext } from '@/providers/SyncProvider';
import { usePageStore } from '@/stores/usePageStore';
import { usePages } from '@/hooks/usePages';

const DocumentPage = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const editable = usePageStore(state => state.editable);
  const loading = usePageStore(state => state.loading);
  const currentPage = usePageStore(state => state.currentPage);
  const { setCurrentPageId, updateCurrentPageContent, updatePageTitle } = usePages();
  const { lastSyncedAt } = useSyncContext();

  useSaveHook();

  const debouncedSetCurrentPageId = useMemo(
    () =>
      debounce((id: string) => {
        void setCurrentPageId(id);
      }, 100),
    [setCurrentPageId]
  );

  useEffect(() => {
    if (pageId) {
      debouncedSetCurrentPageId(pageId);
    }
  }, [debouncedSetCurrentPageId, pageId, setCurrentPageId]);

  const debouncedUpdateContent = useDebounce<string>(updateCurrentPageContent, 300);
  if (!!currentPage && !currentPage.title && !loading) {
    return (
      <div className="w-full flex items-center justify-center">
        <div className="flex-col space-y-4 text-center">
          <div className="text-3xl text-muted-foreground">¯/_(ツ)_/¯</div>
          <div className="text-xl text-muted-foreground">No notes found here</div>
        </div>
      </div>
    );
  }

  if (!currentPage) {
    return null;
  }

  return (
    <div>
      <div className="overflow-y-auto overflow-x-hidden">
        <div className="mx-auto md:max-w-3xl lg:max-w-4xl">
          <PageTitle
            key={currentPage.id}
            editable={editable}
            title={currentPage.title || 'Untitled'}
            onChange={title => void updatePageTitle(currentPage.id, title)}
          />
          <Editor
            key={`${currentPage.id}_${String(lastSyncedAt)}`}
            initialContent={currentPage.content}
            onChange={debouncedUpdateContent}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(DocumentPage);

const useDebounce = <T,>(updateFn: (...args: T[]) => void, delay = 300) => {
  return useMemo(
    () =>
      debounce((...args: T[]) => {
        updateFn(...args);
      }, delay),
    [updateFn, delay]
  );
};
