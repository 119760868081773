import { useCallback, useEffect, useState } from 'react';
import { Copy, CopyCheck, ExternalLink, Globe2, GlobeLock } from 'lucide-react';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { useSyncContext } from '@/providers/SyncProvider';
import { CustomModal } from '../ui/modal';
import { Card } from '../ui/card';
import { useNavigate } from 'react-router-dom';
import Spinner from '../ui/spinner';
import { DropdownMenuItem } from '../ui/dropdown-menu';
import { usePageStore } from '@/stores/usePageStore';
import { usePages } from '@/hooks/usePages';

export function PublishToggle({ setIsOpen }: { setIsOpen: (isOpen: boolean) => void }) {
  const currentPage = usePageStore(state => state.currentPage);

  if (!currentPage) return null;

  return (
    <DropdownMenuItem
      className="cursor-pointer"
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <div className="text-muted-foreground flex items-center gap-2">
        {currentPage.isPublic ? (
          <Globe2 className={'h-4 w-4'} />
        ) : (
          <GlobeLock className="h-4 w-4" />
        )}
        <span className="text-sm font-normal text-muted-foreground">
          {currentPage.isPublic ? 'Make private' : 'Make public'}
        </span>
      </div>
    </DropdownMenuItem>
  );
}

export const ConfirmationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const currentPage = usePageStore(state => state.currentPage);
  const { toggleIsPublic } = usePages();
  const { handleSync } = useSyncContext();

  const [hasPublished, setHasPublished] = useState(false);
  const [loading, setLoading] = useState(false);

  const publish = !currentPage?.isPublic;

  useEffect(() => {
    setHasPublished(false);
  }, [isOpen]);

  const togglePublished = useCallback(async () => {
    if (!currentPage) return;
    setLoading(true);
    await toggleIsPublic(currentPage.id, !currentPage.isPublic);
    await handleSync();
    setTimeout(() => {
      setLoading(false);
    }, 500);
    if (!publish) {
      onClose();
      toast.success('These notes have been set to private.\nOnly you will be able to see them.');
    }
    setHasPublished(true);
  }, [currentPage?.id, currentPage?.isPublic, publish]);

  if (!currentPage) return null;

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <Card className="flex flex-col p-6 pb-4">
        <div className="w-full flex justify-center mb-4">
          {currentPage.isPublic ? (
            <Globe2 className="h-[1.8rem] w-[2rem]" />
          ) : (
            <GlobeLock className="h-[1.8rem] w-[2rem]" />
          )}
        </div>
        {loading ? (
          <div className="w-full flex justify-center items-center h-32">
            <Spinner />
          </div>
        ) : !hasPublished ? (
          currentPage.isPublic ? (
            <PublishedState
              notesId={currentPage.id}
              onConfirm={togglePublished}
              onCancel={onClose}
            />
          ) : (
            <UnpublishedState onConfirm={togglePublished} onCancel={onClose} />
          )
        ) : (
          <OnPublish notesId={currentPage.id} onClose={onClose} />
        )}
      </Card>
    </CustomModal>
  );
};

const OnPublish = ({ notesId, onClose }: { notesId: string; onClose: () => void }) => {
  return (
    <>
      <h2 className="text-center text-lg mb-4">Notes have been successfully made public!</h2>
      <div className="space-y-2 flex flex-col">
        <PublishedLinks notesId={notesId} />
        <Button onClick={onClose} variant={'ghost'}>
          Done
        </Button>
      </div>
    </>
  );
};

const UnpublishedState = ({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
}) => (
  <>
    <h2 className="text-center text-lg mb-4">These notes are currently private.</h2>
    <div className="space-y-2 flex flex-col mt-2">
      <Button
        onClick={onConfirm}
        variant="outline"
        className="bg-green-700 text-white hover:text-white hover:bg-green-600"
      >
        Make public
      </Button>
      <Button onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </div>
  </>
);

const PublishedState = ({
  notesId,
  onConfirm,
  onCancel,
}: {
  notesId: string;
  onConfirm: () => void;
  onCancel: () => void;
}) => (
  <>
    <h2 className="text-center text-lg mb-4">These notes are currently public.</h2>
    <PublishedLinks notesId={notesId} />
    <div className="space-y-2 flex flex-col mt-4">
      <Button
        onClick={onConfirm}
        variant="outline"
        className="bg-green-700 text-white hover:text-white hover:bg-green-600"
      >
        Unpublish!
      </Button>
      <Button onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </div>
  </>
);

const PublishedLinks = ({ notesId }: { notesId: string }) => {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const copyLink = useCallback(() => {
    const link = `${window.location.origin}/shared/${notesId}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
      })
      .catch((error: unknown) => {
        console.error('Failed to copy link: ', error);
      });
  }, [notesId]);

  const goToLink = useCallback(() => {
    navigate(`/shared/${notesId}`);
  }, [notesId, navigate]);

  return (
    <div className="w-full flex justify-around">
      <Button onClick={copyLink} variant={'link'} className="w-[40%] text-green-600 gap-2">
        {copied ? (
          <CopyCheck className="h-[1.2rem] w-[1.2rem]" />
        ) : (
          <Copy className="h-[1.2rem] w-[1.2rem]" />
        )}
        {copied ? 'Copied!' : 'Copy public link'}
      </Button>
      <Button variant={'link'} onClick={goToLink} className="w-[40%] text-green-600 gap-2">
        <ExternalLink className="h-[1.2rem] w-[1.2rem]" />
        Go to public link
      </Button>
    </div>
  );
};
