import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSyncContext } from '@/providers/SyncProvider';
import { usePageStore } from '@/stores/usePageStore';

/**
 * A unified hook that triggers a save function when:
 * - User switches tabs or minimizes window (visibility change)
 * - User closes/refreshes the browser (beforeunload)
 * - User navigates to a different route (cleanup)
 */
export const useSaveHook = () => {
  const { handleSync } = useSyncContext();
  const isDirty = usePageStore(state => state.isDirty);
  const { pageId } = useParams<{ pageId: string }>();

  // Handle tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      void handleSync();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleSync]);

  // Handle browser close/refresh
  useEffect(() => {
    const handleBeforeUnload = () => {
      void handleSync();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleSync]);

  // Handle route changes
  useEffect(() => {
    if (isDirty) {
      void handleSync();
    }
  }, [pageId]);
};
