import { useState } from 'react';
import { Settings, Trash2, HelpCircle, ShieldIcon } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useMediaQuery } from 'usehooks-ts';
import { CustomModal } from '@/components/ui/modal';
import TrashBox from './TrashBox';
import ActionLinkButton from '@/components/ui/ActionLinkButton';
import About from './About';
import AppSettings from './AppSettings';

const SidebarFooter = () => {
  const [settingModalOpen, setSettingsModalOpen] = useState<boolean>(false);
  const [aboutModalOpen, setAboutModalOpen] = useState<boolean>(false);

  return (
    <div className="mt-auto mb-4 px-2">
      <ActionLinkButton
        Icon={Settings}
        title={'Settings'}
        onClick={() => {
          setSettingsModalOpen(true);
        }}
      />

      <Trash />

      <ActionLinkButton
        Icon={HelpCircle}
        title={'About'}
        onClick={() => {
          setAboutModalOpen(true);
        }}
      />

      <ActionLinkButton
        Icon={ShieldIcon}
        title={'Privacy Policy'}
        href={`${window.origin}/privacy-policy`}
        target="_blank"
      />

      <CustomModal
        width={600}
        isOpen={settingModalOpen}
        onClose={() => {
          setSettingsModalOpen(false);
        }}
      >
        <AppSettings
          onClose={() => {
            setSettingsModalOpen(false);
          }}
        />
      </CustomModal>
      <CustomModal
        width={800}
        isOpen={aboutModalOpen}
        onClose={() => {
          setAboutModalOpen(false);
        }}
      >
        <About
          onClose={() => {
            setAboutModalOpen(false);
          }}
        />
      </CustomModal>
    </div>
  );
};

export default SidebarFooter;

export const Trash = ({ collectionId }: { collectionId?: string }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  return (
    <Popover>
      <PopoverTrigger className="w-full">
        <ActionLinkButton Icon={Trash2} title={'Trash'} onClick={() => {}} />
      </PopoverTrigger>
      <PopoverContent side={isMobile ? 'top' : 'right'} className="mx-1 w-72 p-0 z-[999!important]">
        <TrashBox collectionId={collectionId} />
      </PopoverContent>
    </Popover>
  );
};
