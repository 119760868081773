import { Moon, Sun, Droplet, Glasses } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useTheme } from '@/providers/ThemeProvider';
import { useCallback } from 'react';

export function ThemeToggle() {
  const { setTheme, theme } = useTheme();

  const getThemeIcon = useCallback(() => {
    switch (theme) {
      case 'blue':
        return <Droplet className="h-[1.1rem] w-[1.1rem] transition-all" />;
      case 'dark':
        return <Moon className="h-[1.1rem] w-[1.1rem] transition-all" />;
      case 'hue':
        return <Glasses className="h-[1.1rem] w-[1.1rem] transition-all" />;
      default:
        return <Sun className="h-[1.1rem] w-[1.1rem] transition-all" />;
    }
  }, [theme]);

  return (
    <DropdownMenu>
      <Tooltip>
        <TooltipTrigger asChild>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="border-none touch-manipulation rounded-full"
            >
              {getThemeIcon()}
              <span className="sr-only">Toggle theme</span>
            </Button>
          </DropdownMenuTrigger>
        </TooltipTrigger>
        <TooltipContent>
          <p>Toggle Theme</p>
        </TooltipContent>
      </Tooltip>
      <DropdownMenuContent align="center">
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => {
            setTheme('blue');
          }}
        >
          <Droplet className="mr-2 h-4 w-4" />
          <span>Blue</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => {
            setTheme('hue');
          }}
        >
          <Glasses className="mr-2 h-4 w-4" />
          <span>Sepia</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => {
            setTheme('dark');
          }}
        >
          <Moon className="mr-2 h-4 w-4" />
          <span>Dark</span>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer"
          onClick={() => {
            setTheme('light');
          }}
        >
          <Sun className="mr-2 h-4 w-4" />
          <span>Light</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
