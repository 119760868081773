import { Collection, Page, Section } from '@/types';
import { auth } from '../auth/firebase';
import { supabase } from '../utils/supabase';
import { formatISO, getTime } from 'date-fns';
import { decryptData, encryptData } from '@/services/utils/encryptionUtils';

export interface Note {
  id: string;
  createdAt: string;
  updatedAt: string;
  archivedAt: string | null;
  title: string;
  content: string;
  userId: string;
  isPublic: boolean;
  tags: unknown; // JSONB from Supabase comes as unknown
  collectionId: string;
  sectionId: string;
}

export interface SbCollection {
  id: string;
  createdAt: string;
  updatedAt: string;
  archivedAt: string | null;
  title: string;
  userId: string;
  isPublic: boolean;
  tags: unknown; // JSONB from Supabase comes as unknown
  sections: unknown; // JSONB from Supabase comes as unknown
}

interface WithId {
  id: string;
}

export class RemoteDBService {
  private supabase = supabase;
  private readonly maxRetries = 3;
  private readonly retryDelay = 1000;
  private userId: string | null = null;

  constructor() {
    auth.onAuthStateChanged(user => {
      this.userId = user?.uid || null;
    });
  }

  private ensureAuthenticated(): string {
    const currentUser = auth.currentUser;

    if (!currentUser?.uid) {
      throw new Error('User must be authenticated to perform this operation');
    }

    if (!this.userId) {
      this.userId = currentUser.uid;
    }

    return this.userId;
  }

  private async executeBatchWithRetry(
    operation: () => Promise<void>,
    retryCount = 0
  ): Promise<void> {
    try {
      await operation();
    } catch (error) {
      console.error(`Operation failed (attempt ${String(retryCount + 1)}):`, error);
      if (retryCount < this.maxRetries) {
        await new Promise(resolve => setTimeout(resolve, this.retryDelay * (retryCount + 1)));
        return this.executeBatchWithRetry(operation, retryCount + 1);
      }
      throw error;
    }
  }

  private async processBatchOperations<T>(
    notes: T[],
    operation: (notes: T[]) => Promise<void>
  ): Promise<void> {
    const BATCH_SIZE = 1000; // Supabase allows larger batches than Firestore

    const chunks = Array.from({ length: Math.ceil(notes.length / BATCH_SIZE) }, (_, i) =>
      notes.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE)
    );

    for (const chunk of chunks) {
      await this.executeBatchWithRetry(async () => {
        await operation(chunk);
      });
    }
  }

  async bulkAddNotes(items: Page[]): Promise<void> {
    const userId = this.ensureAuthenticated();
    if (!items.length) return;

    await this.processBatchOperations(items, async chunk => {
      const notes = await Promise.all(
        chunk.map(async item => ({
          ...(await this.convertToNote(item)),
          id: item.id,
          userId: userId,
        }))
      );

      const { error } = await this.supabase.from('Note').upsert(notes, {
        onConflict: 'id',
        ignoreDuplicates: false,
      });

      if (error) throw error;
    });
  }

  async bulkUpdateNotes(pages: Page[]): Promise<void> {
    const userId = this.ensureAuthenticated();
    if (!pages.length) return;

    await this.processBatchOperations(pages, async chunk => {
      // Supabase doesn't support bulk updates in a single query
      // We need to handle each update individually
      await Promise.all(
        chunk.map(async page => {
          const { error } = await this.supabase
            .from('Note')
            .update({
              ...(await this.convertToNote(page)),
            })
            .eq('id', page.id)
            .eq('userId', userId);

          if (error) throw error;
        })
      );
    });
  }

  async bulkDeleteItems(table: 'Collection' | 'Note', items: WithId[]): Promise<void> {
    const userId = this.ensureAuthenticated();
    if (!items.length) return;

    await this.processBatchOperations(items, async chunk => {
      const ids = chunk.map(item => item.id);
      const { error } = await this.supabase.from(table).delete().in('id', ids).eq('userId', userId);

      if (error) throw error;
    });
  }

  async bulkAddCollections(collections: Collection[]): Promise<void> {
    this.ensureAuthenticated();
    if (!collections.length) return;

    await this.processBatchOperations(collections, async chunk => {
      // Supabase doesn't support bulk updates in a single query
      // We need to handle each update individually
      await Promise.all(
        chunk.map(async coll => {
          const { error } = await this.supabase
            .from('Collection')
            .upsert(this.convertToSupabaseCollection(coll), {
              onConflict: 'id',
              ignoreDuplicates: false,
            });

          if (error) throw error;
        })
      );
    });
  }

  async bulkUpdateCollections(collections: Collection[]): Promise<void> {
    const userId = this.ensureAuthenticated();
    if (!collections.length) return;

    await this.processBatchOperations(collections, async chunk => {
      // Supabase doesn't support bulk updates in a single query
      // We need to handle each update individually
      await Promise.all(
        chunk.map(async coll => {
          const { error } = await this.supabase
            .from('Collection')
            .update({
              ...this.convertToSupabaseCollection(coll),
            })
            .eq('id', coll.id)
            .eq('userId', userId);

          if (error) throw error;
        })
      );
    });
  }

  async getCollectionChangesSince(lastSyncTime: number | null): Promise<Collection[]> {
    const sbCollections = await this.getTableChangesSince<SbCollection>('Collection', lastSyncTime);
    return sbCollections.map(c => this.convertToLocalCollection(c));
  }

  async getPageChangesSince(lastSyncTime: number | null): Promise<Page[]> {
    const notes = await this.getTableChangesSince<Note>('Note', lastSyncTime);
    return Promise.all(notes.map(n => this.convertToPage(n)));
  }

  private async getTableChangesSince<T>(
    table: 'Note' | 'Collection',
    lastSyncTime: number | null
  ): Promise<T[]> {
    const userId = this.ensureAuthenticated();
    const PAGE_SIZE = 20; // Adjust based on your needs
    let allPages: T[] = [];
    let hasMore = true;
    let start = 0;

    try {
      while (hasMore) {
        let query = this.supabase
          .from(table)
          .select('*')
          .eq('userId', userId)
          .range(start, start + PAGE_SIZE - 1)
          .order('updatedAt', { ascending: true });

        if (lastSyncTime) {
          query = query.gt('updatedAt', new Date(lastSyncTime).toISOString());
        }

        const { data, error } = await query;

        if (error) throw error;
        if (data.length === 0) {
          hasMore = false;
          break;
        }

        allPages = allPages.concat(data);

        if (data.length < PAGE_SIZE) {
          hasMore = false;
        }

        start += PAGE_SIZE;
      }

      return allPages;
    } catch (error) {
      console.error('Error fetching changes:', error);
      throw error;
    }
  }

  async getPublicNote(id: string): Promise<Page | null> {
    try {
      const query = this.supabase.from('Note').select('*').eq('id', id).eq('isPublic', true);

      const { data, error } = await query;

      if (error) throw error;

      return data.length ? await (data as Note[]).map(n => this.convertToPage(n))[0] : null;
    } catch (error) {
      console.error('Error fetching changes:', error);
      throw error;
    }
  }

  // Convert from Supabase Note to your Page type
  private async convertToPage(note: Note): Promise<Page> {
    return {
      id: note.id,
      title: await decryptData(note.title),
      content: await decryptData(note.content),
      createdAt: getTruncatedTime(note.createdAt),
      updatedAt: note.updatedAt ? getTruncatedTime(note.updatedAt) : Date.now(),
      archivedAt: note.archivedAt ? getTruncatedTime(note.archivedAt) : 0,
      isPublic: note.isPublic || false,
      tags: this.parseJSONBTags(note),
      sectionId: note.sectionId,
      collectionId: note.collectionId,
    };
  }

  // Convert from your Page type to Supabase Note
  private async convertToNote(page: Page): Promise<Omit<Note, 'id' | 'user_id'>> {
    const normalizedTags = normalizeTags(page.tags || []);
    return {
      userId: this.userId || '',
      createdAt: formatISO(page.createdAt),
      updatedAt: formatISO(page.updatedAt),
      tags: JSON.stringify(normalizedTags),
      archivedAt: page.archivedAt ? formatISO(page.archivedAt) : null,
      title: await encryptData(page.title),
      content: await encryptData(page.content),
      isPublic: page.isPublic || false,
      sectionId: page.sectionId || '',
      collectionId: page.collectionId || '',
    };
  }

  // Convert from your Page type to Supabase Collection
  private convertToSupabaseCollection(coll: Collection): SbCollection {
    const normalizedTags = normalizeTags(coll.tags || []);
    return {
      id: coll.id,
      userId: this.userId || '',
      title: coll.title,
      isPublic: !!coll.isPublic,
      createdAt: formatISO(coll.createdAt),
      updatedAt: formatISO(coll.updatedAt),
      archivedAt: coll.archivedAt ? formatISO(coll.archivedAt) : null,
      tags: JSON.stringify(normalizedTags),
      sections: JSON.stringify(coll.sections || []),
    };
  }

  // Convert from Supabase Note to your Page type
  private convertToLocalCollection(coll: SbCollection): Collection {
    return {
      id: coll.id,
      title: coll.title,
      isPublic: !!coll.isPublic,
      createdAt: getTruncatedTime(coll.createdAt),
      updatedAt: coll.updatedAt ? getTruncatedTime(coll.updatedAt) : Date.now(),
      archivedAt: coll.archivedAt ? getTruncatedTime(coll.archivedAt) : 0,
      tags: this.parseJSONBTags(coll),
      sections: this.parseJSONBSections(coll),
    };
  }

  private parseJSONBTags(item: Note | SbCollection): string[] {
    let parsedTags: string[] = [];
    try {
      const tagsData =
        typeof item.tags === 'string'
          ? (JSON.parse(item.tags) as string[])
          : (item.tags as string[]);
      parsedTags = Array.isArray(tagsData) ? tagsData : [];
    } catch (e) {
      console.error('Error parsing tags:', e);
      parsedTags = [];
    }
    return parsedTags;
  }

  private parseJSONBSections(item: SbCollection): Section[] {
    let parsedSections: Section[] = [];
    try {
      const sectionsData =
        typeof item.sections === 'string'
          ? (JSON.parse(item.sections) as Section[])
          : (item.tags as Section[]);
      parsedSections = Array.isArray(sectionsData) ? sectionsData : [];
    } catch (e) {
      console.error('Error parsing sections:', e);
      parsedSections = [];
    }
    return parsedSections;
  }
}

const normalizeTags = (tags: string[]): string[] => {
  if (!Array.isArray(tags)) return [];

  return Array.from(
    new Set(
      tags
        .map(tag => tag.trim().toLowerCase()) // Normalize
        .filter(
          (tag): tag is string => typeof tag === 'string' && tag.length > 0 && tag.length <= 100 // Type guard to ensure non-null
        )
    )
  ).slice(0, 50); // Limit to 50 tags
};

const getTruncatedTime = (isoDate: string) => ((getTime(new Date(isoDate)) / 1000) | 0) * 1000;
