import { memo, useCallback } from 'react';
import { Dot, TrashIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { usePages } from '@/hooks/usePages';
import { cn } from '@/lib/utils';
import { useSyncContext } from '@/providers/SyncProvider';
import { usePageStore } from '@/stores/usePageStore';
import { PageMetadata } from '@/types';

const ListItem = ({ metadata }: { metadata: PageMetadata }) => {
  const navigate = useNavigate();
  const { archivePage } = usePages();
  const currentId = usePageStore(state => state.currentPage?.id || '');
  const { lastSyncedAt } = useSyncContext();

  const isUnsynced = !!lastSyncedAt && lastSyncedAt < metadata.updatedAt;
  const isSelected = metadata.id === currentId;

  const onClick = () => {
    if (metadata.collectionId) {
      navigate(`/collection/${metadata.collectionId}/page/${metadata.id}`);
      return;
    }
    navigate(`/page/${metadata.id}`);
  };

  const onArchive = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      await archivePage(metadata.id, metadata.collectionId);
      if (isSelected) {
        navigate(metadata.collectionId ? `/collection/${metadata.collectionId}` : '/');
      }
    },
    [archivePage, metadata.id, metadata.collectionId, isSelected, navigate]
  );

  return (
    <div className="w-full group">
      <div
        onClick={onClick}
        className={cn(
          'mx-2 flex items-center rounded hover:bg-muted cursor-pointer h-9 p-1',
          isSelected ? 'bg-muted' : ''
        )}
      >
        <div className="flex flex-1 items-center flex-grow overflow-hidden">
          <span className="mr-1 text-[14px]">📄</span>
          <span className="text-[14px] truncate">{metadata.title}</span>
        </div>
        <Button
          variant="ghost"
          size="icon"
          className="h-[calc(100%-0.5px)] w-8"
          onClick={onArchive}
        >
          {isUnsynced && (
            <Dot className="block group-hover:hidden h-6 w-6 text-secondary-foreground" />
          )}
          <TrashIcon className="hidden group-hover:block transition-opacity h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default memo(ListItem);
