import { ElementRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

type TitleProp = {
  title: string;
  onChange: (newTitle: string) => void;
  onComplete: () => void;
};

const CollectionTitle: React.FC<TitleProp> = ({ title, onChange, onComplete }) => {
  const inputRef = useRef<ElementRef<'textarea'>>(null);
  const [value, setValue] = useState(title);

  // Update local state when title prop changes
  useEffect(() => {
    setValue(title);
  }, [title]);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, []);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onComplete();
      }
    },
    [onComplete]
  );

  const onInput = useCallback(
    (value: string) => {
      setValue(value);
      if (value) {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <div className="pb-4">
      <div className="w-screen md:w-auto mt-3 mx-0 overflow-x-hidden min-h-[40px] pt-2 px-3 mb-2 bg-accent rounded-lg">
        <TextareaAutosize
          autoFocus
          ref={inputRef}
          spellCheck="false"
          rows={1}
          onKeyDown={onKeyDown}
          placeholder={title === '' ? 'Untitled Collection' : ''}
          value={value}
          onChange={e => {
            onInput(e.target.value);
          }}
          className="resize-none break-words bg-transparent text-lg font-bold outline-none w-full"
        />
      </div>
      <small className="pl-1 pt-4">Click "Enter" to submit</small>
    </div>
  );
};

export default memo(CollectionTitle);
