import { isEqual } from 'lodash-es';
import { subHours } from 'date-fns';
import { INITIAL_PAGES } from '@/services/database/defaultData/defaultNotes';
import { Collection, Page } from '@/types';
import localDB from './LocalDBService';
import { RemoteDBService } from './RemoteDBService';

type ResolvedChanges<T> = {
  toAddLocally: T[];
  toAddRemotely: T[];
  toUpdateLocally: T[];
  toUpdateRemotely: T[];
  toDeleteLocally: T[];
  toDeleteRemotely: T[];
};
export class SyncEngine {
  private remoteDB: RemoteDBService;

  constructor() {
    this.remoteDB = new RemoteDBService();
  }

  async performSync() {
    let lastSync: number | null = await localDB.getLastSyncTime();
    lastSync = lastSync ? subHours(new Date(lastSync), 1).getTime() : null;

    // Sync collections first
    const remoteCollectionChanges = await this.remoteDB.getCollectionChangesSince(lastSync);
    const localCollectionChanges = await localDB.getCollectionsSince(lastSync);
    const resolvedCollections = this.resolveCollectionConflicts(
      remoteCollectionChanges,
      localCollectionChanges
    );
    await this.applyCollectionChanges(resolvedCollections);

    // Sync pages after ensuring collections exist
    const remoteChanges = await this.remoteDB.getPageChangesSince(lastSync);
    const localChanges = await localDB.getNotesSince(lastSync);
    const resolvedChanges = this.resolvePageConflicts(remoteChanges, localChanges);
    await this.applyPageChanges(resolvedChanges);

    const lastSynced = Date.now();
    await localDB.updateLastSyncTime(lastSynced);
    return lastSynced;
  }

  private resolvePageConflicts(remoteChanges: Page[], localChanges: Page[]): ResolvedChanges<Page> {
    const resolvedChanges: ResolvedChanges<Page> = {
      toAddLocally: [],
      toAddRemotely: [],
      toUpdateLocally: [],
      toUpdateRemotely: [],
      toDeleteLocally: [],
      toDeleteRemotely: [],
    };

    const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;

    // Create maps for faster lookups
    const remoteMap = new Map(remoteChanges.map(page => [page.id, page]));
    const localMap = new Map(localChanges.map(page => [page.id, page]));

    // Separate default pages from regular notes
    const isDefaultNote = (page: Page) => INITIAL_PAGES.map(p => p.title).includes(page.title);
    const isOriginalDefaultNote = (page: Page) => {
      // Check if this note matches exactly with the original default note
      const matchingDefault = INITIAL_PAGES.find(defaultPage => defaultPage.title === page.title);
      if (!matchingDefault) return false;
      return isEqual(page.createdAt, page.updatedAt);
    };

    const remoteDefaultPages = remoteChanges.filter(page => isDefaultNote(page));
    const localDefaultPages = localChanges.filter(page => isDefaultNote(page));

    // Handle default pages separately
    for (const defaultPage of INITIAL_PAGES) {
      const localPages = localDefaultPages
        .filter(p => p.title === defaultPage.title)
        .sort((a, b) => b.updatedAt - a.updatedAt);
      const remotePages = remoteDefaultPages
        .filter(remotePage => remotePage.title === defaultPage.title)
        .sort((a, b) => b.updatedAt - a.updatedAt);

      const localPage = localPages.length > 0 ? localPages[0] : undefined;
      const remotePage = remotePages.length > 0 ? remotePages[0] : undefined;

      const localToDelete = localPage ? localPages.filter(p => p.id !== localPage.id) : [];
      const remoteToDelete = remotePage ? remotePages.filter(p => p.id !== remotePage.id) : [];
      resolvedChanges.toDeleteLocally.push(...localToDelete);
      resolvedChanges.toDeleteRemotely.push(...remoteToDelete);

      if (isEqual(remotePage, localPage)) {
        continue;
      }

      if (remotePage && localPage) {
        if (localPage.updatedAt > remotePage.updatedAt && !isOriginalDefaultNote(localPage)) {
          if (remoteMap.get(localPage.id)) {
            resolvedChanges.toUpdateRemotely.push(localPage);
          } else {
            resolvedChanges.toAddRemotely.push(localPage);
            resolvedChanges.toDeleteRemotely.push(remotePage);
          }
        } else {
          // add the local one as the remote one
          if (localMap.get(remotePage.id)) {
            resolvedChanges.toUpdateLocally.push(remotePage);
          } else {
            resolvedChanges.toAddLocally.push(remotePage);
            resolvedChanges.toDeleteLocally.push(localPage);
          }
        }
      } else if (localPage && !isOriginalDefaultNote(localPage)) {
        resolvedChanges.toAddRemotely.push(localPage);
      } else if (remotePage && !isOriginalDefaultNote(remotePage)) {
        resolvedChanges.toAddLocally.push(remotePage);
      }
    }

    // Regular notes (excluding default pages)
    const remoteRegularPages = remoteChanges.filter(page => !isDefaultNote(page));
    const localRegularPages = localChanges.filter(page => !isDefaultNote(page));

    // Process remote changes
    for (const remotePage of remoteRegularPages) {
      const localPage = localMap.get(remotePage.id);

      // Handle deletions first
      if (remotePage.archivedAt && remotePage.archivedAt < oneWeekAgo) {
        resolvedChanges.toDeleteLocally.push(remotePage);
        continue;
      }

      if (isEqual(remotePage, localPage)) {
        continue;
      }

      if (!localPage) {
        // New remote page
        resolvedChanges.toAddLocally.push(remotePage);
      } else {
        // Conflict resolution based on updatedAt timestamp
        if (remotePage.updatedAt > localPage.updatedAt) {
          resolvedChanges.toUpdateLocally.push(remotePage);
        }
      }
    }

    // Process local changes
    for (const localPage of localRegularPages) {
      const remotePage = remoteMap.get(localPage.id);

      // Handle deletions first
      if (localPage.archivedAt && localPage.archivedAt < oneWeekAgo) {
        resolvedChanges.toDeleteRemotely.push(localPage);
        resolvedChanges.toDeleteLocally.push(localPage);
        continue;
      }

      if (isEqual(remotePage, localPage)) {
        continue;
      }

      if (!remotePage) {
        // New local page
        resolvedChanges.toAddRemotely.push(localPage);
      } else {
        // Conflict resolution based on updatedAt timestamp
        if (localPage.updatedAt > remotePage.updatedAt) {
          resolvedChanges.toUpdateRemotely.push(localPage);
        }
      }
    }

    // Deduplicate arrays to prevent any potential double-processing
    const deduplicate = (array: Page[]) =>
      Array.from(new Map(array.map(item => [item.id, item])).values());

    return {
      toAddLocally: deduplicate(resolvedChanges.toAddLocally),
      toAddRemotely: deduplicate(resolvedChanges.toAddRemotely),
      toUpdateLocally: deduplicate(resolvedChanges.toUpdateLocally),
      toUpdateRemotely: deduplicate(resolvedChanges.toUpdateRemotely),
      toDeleteLocally: deduplicate(resolvedChanges.toDeleteLocally),
      toDeleteRemotely: deduplicate(resolvedChanges.toDeleteRemotely),
    };
  }

  private async applyPageChanges(resolvedChanges: ResolvedChanges<Page>) {
    const {
      toAddLocally,
      toUpdateLocally,
      toDeleteLocally,
      toAddRemotely,
      toUpdateRemotely,
      toDeleteRemotely,
    } = resolvedChanges;

    await localDB.bulkUpsertPages([...toAddLocally, ...toUpdateLocally]);
    await localDB.bulkDeletePages(toDeleteLocally);

    await this.remoteDB.bulkAddNotes(toAddRemotely);
    await this.remoteDB.bulkUpdateNotes(toUpdateRemotely);
    await this.remoteDB.bulkDeleteItems('Note', toDeleteRemotely);
  }

  private resolveCollectionConflicts(
    remoteChanges: Collection[],
    localChanges: Collection[]
  ): ResolvedChanges<Collection> {
    const resolvedChanges: ResolvedChanges<Collection> = {
      toAddLocally: [],
      toAddRemotely: [],
      toUpdateLocally: [],
      toUpdateRemotely: [],
      toDeleteLocally: [],
      toDeleteRemotely: [],
    };

    const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;

    // Create maps for faster lookups
    const remoteMap = new Map(remoteChanges.map(collection => [collection.id, collection]));
    const localMap = new Map(localChanges.map(collection => [collection.id, collection]));

    // Process remote changes
    for (const remotePage of remoteChanges) {
      const localPage = localMap.get(remotePage.id);

      // Handle deletions first
      if (remotePage.archivedAt && remotePage.archivedAt < oneWeekAgo) {
        resolvedChanges.toDeleteLocally.push(remotePage);
        continue;
      }

      if (isEqual(remotePage, localPage)) {
        continue;
      }

      if (!localPage) {
        // New remote page
        resolvedChanges.toAddLocally.push(remotePage);
      } else {
        // Conflict resolution based on updatedAt timestamp
        if (remotePage.updatedAt > localPage.updatedAt) {
          resolvedChanges.toUpdateLocally.push(remotePage);
        }
      }
    }

    // Process local changes
    for (const localPage of localChanges) {
      const remotePage = remoteMap.get(localPage.id);

      // Handle deletions first
      if (localPage.archivedAt && localPage.archivedAt < oneWeekAgo) {
        resolvedChanges.toDeleteRemotely.push(localPage);
        resolvedChanges.toDeleteLocally.push(localPage);
        continue;
      }

      if (isEqual(remotePage, localPage)) {
        continue;
      }

      if (!remotePage) {
        // New local page
        resolvedChanges.toAddRemotely.push(localPage);
      } else {
        // Conflict resolution based on updatedAt timestamp
        if (localPage.updatedAt > remotePage.updatedAt) {
          resolvedChanges.toUpdateRemotely.push(localPage);
        }
      }
    }

    // Deduplicate arrays to prevent any potential double-processing
    const deduplicate = (array: Collection[]) =>
      Array.from(new Map(array.map(item => [item.id, item])).values());

    return {
      toAddLocally: deduplicate(resolvedChanges.toAddLocally),
      toAddRemotely: deduplicate(resolvedChanges.toAddRemotely),
      toUpdateLocally: deduplicate(resolvedChanges.toUpdateLocally),
      toUpdateRemotely: deduplicate(resolvedChanges.toUpdateRemotely),
      toDeleteLocally: deduplicate(resolvedChanges.toDeleteLocally),
      toDeleteRemotely: deduplicate(resolvedChanges.toDeleteRemotely),
    };
  }

  private async applyCollectionChanges(resolvedChanges: ResolvedChanges<Collection>) {
    const {
      toAddLocally,
      toUpdateLocally,
      toDeleteLocally,
      toAddRemotely,
      toUpdateRemotely,
      toDeleteRemotely,
    } = resolvedChanges;

    await localDB.bulkUpsertCollections([...toAddLocally, ...toUpdateLocally]);
    await localDB.bulkDeleteCollections(toDeleteLocally);

    await this.remoteDB.bulkAddCollections(toAddRemotely);
    await this.remoteDB.bulkUpdateCollections(toUpdateRemotely);
    await this.remoteDB.bulkDeleteItems('Collection', toDeleteRemotely);
  }
}
