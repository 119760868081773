import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { usePages } from '@/hooks/usePages';

const AddPage = () => {
  const { addPage } = usePages();
  const navigate = useNavigate();
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const pageId = await addPage();
    navigate(`/page/${String(pageId)}`);
  };
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div>
            <Button
              onClick={handleSubmit}
              variant="ghost"
              size="icon"
              className="p-0 m-0 h-8 w-8 outline-none"
            >
              <Plus className="h-4 w-4" />
            </Button>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Add Page</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default AddPage;
