import { useCallback, useEffect, useState } from 'react';
import { Archive, ChevronDown, ChevronRight, Ellipsis, FolderPen, Plus } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import ListItem from '@/components/MainSidebar/PageList/ListItem';
import { DisplayedCollectionSection, Section } from '@/types';
import { Button } from '@/components/ui/button';
import { CustomModal } from '@/components/ui/modal';
import CollectionTitle from './Title';

// Container for multiple sections
const NestedListContainer = ({
  displayedSections,
  onUpdate,
  disableDraggable = false,

  onArchiveSection,
  onAddSectionPage,
}: {
  displayedSections: DisplayedCollectionSection[];
  onUpdate: (updates: DisplayedCollectionSection[]) => Promise<void>;
  disableDraggable?: boolean;

  onAddSectionPage: (sectionId: string, orderNumber: number) => Promise<void>;
  onArchiveSection: (sectionId: string) => Promise<void>;
}) => {
  const [currentSection, setCurrentSection] = useState<Section | null>(null);

  const [sortedSections, setSortedSections] = useState<DisplayedCollectionSection[]>(
    [...displayedSections].sort((a, b) => a.section.orderNumber - b.section.orderNumber)
  );

  // State to track expanded/collapsed state for all sections
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>(() => {
    // Initialize all sections as expanded
    return displayedSections.reduce<Record<string, boolean>>((acc, section) => {
      acc[section.section.id] = true;
      return acc;
    }, {});
  });

  // Toggle section expand/collapse
  const toggleSection = (sectionId: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering drag when clicking the toggle
    setExpandedSections(prev => ({
      ...prev,
      [sectionId]: !prev[sectionId],
    }));
  };

  // Handle drag end for both sections and pages
  const onDragEnd = useCallback((result: DropResult) => {
    if (!result.destination) return;

    const { source, destination, type } = result;

    // Handle section reordering
    if (type === 'SECTION') {
      setSortedSections(prevSections => {
        const newSections = reorderSections(prevSections, source.index, destination.index);
        void onUpdate(newSections);
        return newSections;
      });
      return;
    }

    // Handle page dragging
    const sourceSectionId = source.droppableId.replace('section-', '');
    const destSectionId = destination.droppableId.replace('section-', '');

    if (sourceSectionId === destSectionId) {
      // Same section page reordering
      setSortedSections(prevSections => {
        const newSections = reorderPagesInSection(
          prevSections,
          sourceSectionId,
          source.index,
          destination.index
        );
        void onUpdate(newSections);
        return newSections;
      });
    } else {
      // Cross-section page moving
      setSortedSections(prevSections => {
        const newSections = movePageBetweenSections(
          prevSections,
          sourceSectionId,
          destSectionId,
          source.index,
          destination.index
        );
        void onUpdate(newSections);
        return newSections;
      });
    }
  }, []);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sections" type="SECTION">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="w-full space-y-2">
              {sortedSections.map((displayedSection, index) => (
                <Draggable
                  key={displayedSection.section.id}
                  draggableId={`section-draggable-${displayedSection.section.id}`}
                  index={index}
                  isDragDisabled={disableDraggable}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        ...provided.draggableProps.style,
                        opacity: snapshot.isDragging ? 0.7 : 1,
                      }}
                      className="mb-2 pl-2"
                    >
                      {/* Section header */}
                      <div
                        className="flex items-center px-2.5 pr-4 py-1 cursor-pointer rounded w-full group"
                        {...provided.dragHandleProps}
                        onClick={e => {
                          toggleSection(displayedSection.section.id, e);
                        }}
                      >
                        <div className="flex flex-grow gap-2 items-center">
                          <span className="cursor-pointer">
                            {expandedSections[displayedSection.section.id] ? (
                              <ChevronDown className="h-4 w-4" />
                            ) : (
                              <ChevronRight className="h-4 w-4" />
                            )}
                          </span>
                          <h3 className="font-semibold text-sm">
                            {displayedSection.section.title}
                          </h3>
                        </div>
                        <div className="gap-2 flex items-center">
                          <Button
                            variant="ghost"
                            size="icon"
                            className="w-6 h-6"
                            onClick={e => {
                              e.stopPropagation();
                              void onAddSectionPage(
                                displayedSection.section.id,
                                displayedSection.section.pages.length
                              );
                            }}
                          >
                            <Plus className="h-4 w-4" />
                          </Button>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                className="w-6 h-6"
                                onClick={e => {
                                  e.stopPropagation();
                                }}
                              >
                                <Ellipsis className="h-4 w-4" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="center" className="z-[9999]">
                              <DropdownMenuItem
                                className="cursor-pointer"
                                onClick={e => {
                                  e.stopPropagation();
                                  setCurrentSection(displayedSection.section);
                                }}
                              >
                                <FolderPen className="mr-2 h-4 w-4" />
                                <span>Rename</span>
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                className="cursor-pointer"
                                onClick={e => {
                                  e.stopPropagation();
                                  void onArchiveSection(displayedSection.section.id);
                                }}
                              >
                                <Archive className="mr-2 h-4 w-4" />
                                <span>Archive</span>
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                      </div>

                      {/* Pages list - only render if section is expanded */}
                      {expandedSections[displayedSection.section.id] && (
                        <Droppable
                          droppableId={`section-${displayedSection.section.id}`}
                          type="PAGE"
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              className="w-full space-y-1 pl-2 pr-2.5 mt-1"
                            >
                              {displayedSection.section.pages
                                .sort((a, b) => (a.orderNumber || 0) - (b.orderNumber || 0))
                                .map((page, pageIndex) => (
                                  <Draggable
                                    key={page.id}
                                    draggableId={page.id}
                                    index={pageIndex}
                                    isDragDisabled={disableDraggable}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          ...provided.draggableProps.style,
                                          opacity: snapshot.isDragging ? 0.5 : 1,
                                        }}
                                      >
                                        <ListItem metadata={page} />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <EditSectionModal
        isOpen={!!currentSection}
        section={currentSection}
        sections={displayedSections}
        onClose={() => {
          setCurrentSection(null);
        }}
        onUpdate={onUpdate}
      />
    </>
  );
};

export default NestedListContainer;

const EditSectionModal: React.FC<{
  section: Section | null;
  isOpen: boolean;
  onClose: () => void;
  sections: DisplayedCollectionSection[];
  onUpdate: (updates: DisplayedCollectionSection[]) => Promise<void>;
}> = ({ section, sections, isOpen, onClose, onUpdate }) => {
  const [title, setTitle] = useState('');

  // Update local title state when section changes
  useEffect(() => {
    if (section) {
      setTitle(section.title || 'Untitled Section');
    }
  }, [section]);

  const updateSection = useCallback(async () => {
    if (!section) return;

    const newSections = sections.map(s =>
      s.section.id === section.id ? { ...s, section: { ...s.section, title } } : s
    );

    await onUpdate(newSections);
    onClose();
  }, [section, sections, onUpdate, title, onClose]);

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <div className="pt-2 px-4">
        <h4 className="pl-1">Update Section Title</h4>
        <CollectionTitle title={title} onComplete={updateSection} onChange={setTitle} />
      </div>
    </CustomModal>
  );
};

// Pure function to reorder sections
const reorderSections = (
  sections: DisplayedCollectionSection[],
  sourceIndex: number,
  destinationIndex: number
): DisplayedCollectionSection[] => {
  const result = [...sections];
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);

  // Update orderNumber for all sections
  result.forEach((section, index) => {
    section.section.orderNumber = index;
  });

  return result;
};

// Pure function to reorder pages within the same section
const reorderPagesInSection = (
  sections: DisplayedCollectionSection[],
  sectionId: string,
  sourceIndex: number,
  destinationIndex: number
): DisplayedCollectionSection[] => {
  return sections.map(section => {
    if (section.section.id === sectionId) {
      // Create a copy of pages array
      let pages = [...section.section.pages];
      const [removed] = pages.splice(sourceIndex, 1);
      pages.splice(destinationIndex, 0, removed);

      // Update orderNumber values
      pages = pages.map((page, index) => ({ ...page, orderNumber: index }));

      // Return updated section
      return {
        ...section,
        section: {
          ...section.section,
          pages,
        },
      };
    }
    return section;
  });
};

// Pure function to move a page between sections
const movePageBetweenSections = (
  sections: DisplayedCollectionSection[],
  sourceSectionId: string,
  destinationSectionId: string,
  sourceIndex: number,
  destinationIndex: number
): DisplayedCollectionSection[] => {
  // Find source and destination sections
  const sourceSection = sections.find(s => s.section.id === sourceSectionId);
  const destSection = sections.find(s => s.section.id === destinationSectionId);

  if (!sourceSection || !destSection) return sections;

  // Get the page being moved
  const sourcePage = sourceSection.section.pages[sourceIndex];

  // Create updated page with new sectionId
  const updatedPage = {
    ...sourcePage,
    sectionId: destinationSectionId,
  };

  return sections.map(section => {
    // Update source section (remove page)
    if (section.section.id === sourceSectionId) {
      const newPages = [...section.section.pages];
      newPages.splice(sourceIndex, 1);

      // Update orderNumbers
      newPages.forEach((page, index) => {
        page.orderNumber = index;
      });

      return {
        ...section,
        section: {
          ...section.section,
          pages: newPages,
        },
      };
    }

    // Update destination section (add page)
    if (section.section.id === destinationSectionId) {
      const newPages = [...section.section.pages];
      newPages.splice(destinationIndex, 0, updatedPage);

      // Update orderNumbers
      newPages.forEach((page, index) => {
        page.orderNumber = index;
      });

      return {
        ...section,
        section: {
          ...section.section,
          pages: newPages,
        },
      };
    }

    return section;
  });
};
