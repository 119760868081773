import { Camera } from 'lucide-react';
import { Link } from 'react-router-dom';

type BaseProps = {
  title: string;
  Icon: typeof Camera;
  className?: string;
};

type LinkProps = BaseProps & {
  href: string;
  onClick?: never;
  target?: '_blank' | '_self';
};

type ActionProps = BaseProps & {
  href?: never;
  target?: never;
  onClick: () => void;
};

type ActionLinkButtonProps = LinkProps | ActionProps;

const ActionLinkButton = ({
  title,
  Icon,
  href,
  onClick,
  className = '',
  target,
}: ActionLinkButtonProps) => {
  const baseClasses =
    'flex items-center w-full pl-1 py-2 text-sm rounded-md transition-colors duration-150 hover:bg-muted cursor-pointer';
  const combinedClasses = `${baseClasses} ${className}`.trim();

  if (href) {
    return (
      <Link
        to={href}
        target={target}
        className={combinedClasses}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      >
        <Icon className="w-4 h-4 ml-1 mr-2" />
        {title}
      </Link>
    );
  }

  return (
    <div onClick={onClick} className={combinedClasses}>
      <Icon className="w-4 h-4 ml-1 mr-2" />
      {title}
    </div>
  );
};

export default ActionLinkButton;
