import { useState } from 'react';
import { ArrowLeftIcon } from 'lucide-react';
import { Collection } from '@/types';
import { CustomModal } from '@/components/ui/modal';
import CollectionTitle from './Title';
import { useCollectionStore } from '@/stores/useCollectionStore';
import { useCollections } from '@/hooks/useCollections';

type SidebarHeaderProps = {
  currentCollection: Collection;
  onBackClick: () => void;
};

const SidebarHeader = ({ currentCollection, onBackClick }: SidebarHeaderProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className="w-full flex my-0.5 items-center">
        <div onClick={onBackClick} className="hover:cursor-pointer rounded-full shrink-0">
          <ArrowLeftIcon size={15} className="w-10 h-15" />
        </div>
        <div
          className="ml-[-4px] flex flex-1 cursor-pointer overflow-x-hidden"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <div className="py-4 w-[80%] text-lg font-bold  outline-none truncate">
            {currentCollection.title}
          </div>
        </div>
      </div>
      <TitleUpdateModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default SidebarHeader;

const TitleUpdateModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const currentCollection = useCollectionStore(state => state.currentCollection);
  const { updateCollection } = useCollections();

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <div className="pt-4 px-4">
        <h4 className="pl-1">Update Collection Title</h4>
        <CollectionTitle
          title={currentCollection?.title || 'Untitled Collection'}
          onComplete={onClose}
          onChange={val => {
            if (!currentCollection) return;
            void updateCollection(currentCollection.id, { title: val });
          }}
        />
      </div>
    </CustomModal>
  );
};
