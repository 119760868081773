import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatTimeAgo(timestamp: number | undefined): string {
  if (!timestamp) {
    return '';
  }
  const now = Date.now();
  const diffInSeconds = Math.floor((now - timestamp) / 1000);
  const minutes = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  switch (true) {
    case diffInSeconds < 300:
      return 'recently';
    case diffInSeconds < 3600:
      return `${String(minutes)} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    case diffInSeconds < 86400:
      return `${String(hours)}${hours === 1 ? 'hr' : 'hrs'} ago`;
    case diffInSeconds < 2592000:
      return `${String(days)}d ago`;
    case diffInSeconds < 31536000:
      return `${String(months)}mo ago`;
    default:
      return `${String(years)}${years === 1 ? 'yr' : 'yrs'} ago`;
  }
}
