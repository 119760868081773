import React, { useMemo } from 'react';
import ListItem from './ListItem';
import { usePageStore } from '@/stores/usePageStore';

const ListContainer = () => {
  const pagesMetadata = usePageStore(page => page.pagesMetadata);

  // Render an empty message component
  const emptyMessage = useMemo(() => {
    if (pagesMetadata.length === 0) {
      return <span className="text-sm text-muted-foreground px-3 pt-3">Add more notes here</span>;
    }
    return null;
  }, [pagesMetadata.length]);

  return (
    <>
      {emptyMessage}
      {pagesMetadata
        .filter(metadata => !!metadata.id)
        .map(metadata => (
          <ListItem key={metadata.id} metadata={metadata} />
        ))}
    </>
  );
};

export default React.memo(ListContainer);
