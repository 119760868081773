import { ReactNode, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import MainSidebar from './MainSidebar';
import { Button } from './ui/button';
import { ArrowUp } from 'lucide-react';
import { useKeyboardCommands } from '@/hooks/useKeyboardCommands';

type MainLayoutProps = {
  children: ReactNode;
  Menu: ReactNode;
};

const MainLayout = ({ children, Menu }: MainLayoutProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { pageId } = useParams<{ pageId: string }>();
  useKeyboardCommands();

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          scrollToTop();
        }, 100);
      });
    }
  }, [pageId]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      setShowScrollButton(container.scrollTop > 400);
    };

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="flex h-screen">
      <MainSidebar />
      <main ref={containerRef} className="flex-1 overflow-y-auto relative overflow-x-hidden">
        {Menu}
        {children}
        {showScrollButton && (
          <Button
            onClick={scrollToTop}
            className="fixed bottom-4 right-4 h-8 w-8 p-0 z-1000"
            variant="secondary"
            size="icon"
          >
            <ArrowUp className="h-4 w-4" />
          </Button>
        )}
      </main>
    </div>
  );
};

export default MainLayout;
