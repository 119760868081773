import { nanoid } from 'nanoid';
import { db } from './database';
import { BaseRepository } from './BaseRepository';

export class SyncRepository extends BaseRepository {
  async getLastSyncTime(): Promise<number> {
    const metadata = await db.syncMetadata.get('global');
    return metadata?.lastSyncedAt || 0;
  }

  async updateLastSyncTime(timestamp: number): Promise<void> {
    await db.syncMetadata.put({
      id: 'global',
      lastSyncedAt: timestamp,
      deviceId: (await db.syncMetadata.get('global'))?.deviceId || nanoid(),
    });
  }
}

export default new SyncRepository();
