import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FolderOpen } from 'lucide-react';
import ActionLinkButton from '@/components/ui/ActionLinkButton';
import SidebarWrapper from './SidebarWrapper';
import Header from './Header';
import PageList from './PageList';
import PageListHeader from './PageListHeader';
import SidebarFooter from './SidebarFooter';
import SearchButton from './Search';
import { usePages } from '@/hooks/usePages';

const Sidebar = () => {
  const navigate = useNavigate();

  const { setCurrentPageId } = usePages();

  const goToCollections = useCallback(async () => {
    await setCurrentPageId(null);
    navigate(`/collections`);
  }, [navigate, setCurrentPageId]);

  return (
    <SidebarWrapper>
      <Header />
      <div className="px-2">
        <SearchButton />
        <ActionLinkButton Icon={FolderOpen} title={'Collections'} onClick={goToCollections} />
      </div>
      <div className="flex-grow">
        <PageListHeader />
        <PageList />
      </div>
      <SidebarFooter />
    </SidebarWrapper>
  );
};

export default Sidebar;
