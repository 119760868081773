import { nanoid } from 'nanoid';
import { subWeeks } from 'date-fns';
import { Collection, OrderOption, Page, PageMetadata, Section } from '@/types';
import { db } from './database';
import { INITIAL_PAGES } from '@/services/database/defaultData/defaultNotes';
class LocalDBService {
  async getLastSyncTime(): Promise<number> {
    const metadata = await db.syncMetadata.get('global');
    return metadata?.lastSyncedAt || 0;
  }

  get now() {
    return ((Date.now() / 1000) | 0) * 1000;
  }

  async getCollectionsSince(syncTime: number | null) {
    const collections = await db.collections
      .where('updatedAt')
      .above(syncTime || 0)
      .toArray();

    return collections.map(n => ({
      ...n,
      createdAt: ((n.createdAt / 1000) | 0) * 1000,
      updatedAt: ((n.updatedAt / 1000) | 0) * 1000,
      archivedAt: n.archivedAt ? ((n.archivedAt / 1000) | 0) * 1000 : 0,
    }));
  }

  async getNotesSince(syncTime: number | null) {
    const notes = await db.pages
      .where('updatedAt')
      .above(syncTime || 0)
      .toArray();

    return notes.map(n => ({
      ...n,
      createdAt: ((n.createdAt / 1000) | 0) * 1000,
      updatedAt: ((n.updatedAt / 1000) | 0) * 1000,
      archivedAt: n.archivedAt ? ((n.archivedAt / 1000) | 0) * 1000 : 0,
    }));
  }

  async updateLastSyncTime(timestamp: number): Promise<void> {
    await db.syncMetadata.put({
      id: 'global',
      lastSyncedAt: timestamp,
      deviceId: (await db.syncMetadata.get('global'))?.deviceId || nanoid(),
    });
  }

  async getAllPages(orderBy: OrderOption = 'updatedAt'): Promise<Page[]> {
    if (orderBy !== 'orderNumber') {
      return db.pages.where('archivedAt').equals(0).reverse().sortBy(orderBy);
    }
    return db.pages.where('archivedAt').equals(0).sortBy(orderBy);
  }

  async getPagesMetadata(
    orderBy: OrderOption = 'updatedAt',
    filters: string[] = []
  ): Promise<PageMetadata[]> {
    let query = db.pagesMetadata
      .where('archivedAt')
      .equals(0)
      .and(page => !page.collectionId);
    if (filters.length) {
      query = query.and(page => {
        if (!page.tags) return false;
        const filterSet = new Set(filters);
        return page.tags.some(tag => filterSet.has(tag));
      });
    }
    const pages = await (orderBy === 'orderNumber'
      ? query.sortBy(orderBy)
      : query.reverse().sortBy(orderBy));

    return pages;
  }

  async getPageById(id: string): Promise<Page | undefined> {
    return db.pages.get(id);
  }

  async addPage(pageData?: Partial<Page>): Promise<string> {
    const pageId = pageData?.id || nanoid();
    const metadata: PageMetadata = {
      id: pageId,
      title: pageData?.title || 'Untitled',
      createdAt: this.now,
      updatedAt: this.now,
      archivedAt: 0,
      isPublic: pageData?.isPublic || false,
      tags: pageData?.tags || [],
      // for collection related page metadata
      collectionId: pageData?.collectionId || '',
      sectionId: pageData?.sectionId || '',
      orderNumber: pageData?.orderNumber || 0,
    };
    const newPage = { ...metadata, content: pageData?.content || '' };

    const exists = await db.pages.get(pageId);
    if (exists) return pageId;

    await Promise.all([db.pages.add(newPage), db.pagesMetadata.add(metadata)]);
    return pageId;
  }

  async addDefaultPages() {
    const hasPages = await db.pages.count();
    if (hasPages) {
      return;
    }
    for (const p of INITIAL_PAGES) {
      await this.addPage({
        title: p.title,
        content: p.content ? JSON.stringify(p.content) : '',
      });
    }
  }

  async updatePage(id: string, patch: Partial<Page>) {
    const metadata = { ...patch };
    if (metadata.content) {
      delete metadata.content;
    }
    const updatedAt = this.now;
    await Promise.all([
      db.pagesMetadata.update(id, { ...metadata, updatedAt }),
      db.pages.update(id, { ...patch, updatedAt }),
    ]);
  }

  async updateIsPublic(id: string, isPublic: boolean) {
    return this.updatePage(id, { isPublic: isPublic });
  }

  async archivePage(id: string) {
    return this.updatePage(id, { archivedAt: Date.now() });
  }

  async unarchivePage(id: string) {
    await this.updatePage(id, { archivedAt: 0 });
    const page = await db.pages.get(id);
    return page;
  }

  async deletePage(id: string): Promise<void> {
    await Promise.all([db.pages.delete(id), db.pagesMetadata.delete(id)]);
  }

  async searchPages(
    query: string,
    archived: boolean = false,
    collectionId?: string
  ): Promise<PageMetadata[]> {
    const lowercaseQuery = query.toLowerCase();

    let pagesQuery = db.pagesMetadata.reverse();
    pagesQuery = pagesQuery.and(page => (archived ? !!page.archivedAt : !page.archivedAt));
    if (collectionId) {
      pagesQuery = pagesQuery.and(page => page.collectionId === collectionId);
    }
    pagesQuery = pagesQuery.and(page => page.title.toLowerCase().includes(lowercaseQuery));
    return await pagesQuery.sortBy('updatedAt');
  }

  async getArchivedPages(collectionId?: string): Promise<PageMetadata[]> {
    const query = db.pagesMetadata.where('archivedAt').notEqual(0);

    if (collectionId) {
      query.and(page => page.collectionId === collectionId);
    }
    return query.reverse().sortBy('updatedAt');
  }

  async cleanupOldArchivedPages(): Promise<void> {
    const oneWeekAgo = subWeeks(new Date(), 1).getTime();
    await db.pages
      .where('archivedAt')
      .notEqual(0)
      .and(page => !page.archivedAt || page.archivedAt < oneWeekAgo)
      .delete();
  }

  async bulkUpsertPages(pages: Page[]): Promise<void> {
    await Promise.all([db.pagesMetadata.bulkPut(pages), db.pages.bulkPut(pages)]);
  }

  async bulkDeletePages(pages: Page[]): Promise<void> {
    await Promise.all([
      db.pagesMetadata.bulkDelete(pages.map(p => p.id)),
      db.pages.bulkDelete(pages.map(p => p.id)),
    ]);
  }

  // TAGS
  async assignTag(pageId: string, tagName: string) {
    const normalizedTag = tagName.trim().toLowerCase();

    // Update page's tags
    const page = await this.getPageById(pageId);
    if (page) {
      const tags = new Set(page.tags || []);
      tags.add(normalizedTag);
      await this.updatePage(pageId, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async unassignTag(pageId: string, tagName: string) {
    const normalizedTag = tagName.trim().toLowerCase();

    // Update page's tags
    const page = await this.getPageById(pageId);
    if (page && page.tags) {
      const tags = new Set(page.tags);
      tags.delete(normalizedTag);
      await this.updatePage(pageId, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async editTag(oldName: string, newName: string) {
    const normalizedOld = oldName.trim().toLowerCase();
    const normalizedNew = newName.trim().toLowerCase();

    if (normalizedOld === normalizedNew) return;

    // Update all pages that have this tag
    const pages = await db.pages.where('tags').anyOf([normalizedOld]).toArray();
    for (const page of pages) {
      const tags = new Set(page.tags || []);
      tags.delete(normalizedOld);
      tags.add(normalizedNew);
      await this.updatePage(page.id, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async deleteTag(tagName: string) {
    const normalizedTag = tagName.trim().toLowerCase();

    // Remove tag from all pages
    const pages = await db.pages.where('tags').anyOf([normalizedTag]).toArray();
    for (const page of pages) {
      const tags = new Set(page.tags || []);
      tags.delete(normalizedTag);
      await this.updatePage(page.id, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async getTags() {
    const pages = await db.pages.toArray();
    const tagSet = new Set(pages.map(p => p.tags || []).flat());
    const orderedTags = [...tagSet].sort((a, b) => a.localeCompare(b));
    return [...orderedTags];
  }

  // COLLECTIONS
  async getCollectionById(id: string): Promise<Collection | undefined> {
    return db.collections.get(id);
  }

  async getCollections(): Promise<Collection[]> {
    return db.collections.where('archivedAt').equals(0).reverse().sortBy('updatedAt');
  }

  async addCollection(): Promise<Collection> {
    const collectionId = nanoid();
    const sectionId = nanoid();
    const sections = [{ id: sectionId, title: 'Section 1', orderNumber: 0 }] as Section[];
    const newCollection: Collection = {
      id: collectionId,
      title: 'Untitled Collection',
      createdAt: this.now,
      updatedAt: this.now,
      archivedAt: 0,
      sections,
    };
    await db.collections.add(newCollection);
    await this.addPage({ collectionId, sectionId, orderNumber: 0 });
    return newCollection;
  }

  async archiveCollectionData(collection: Collection, sectionId?: string) {
    const pages = await this.getCollectionPageMetadata(collection.id, sectionId);
    const updates = pages.map(page => this.archivePage(page.id));
    await Promise.all(updates);
    const collectionUpdates: Partial<Collection> = { updatedAt: this.now };
    if (!sectionId) {
      collectionUpdates.archivedAt = this.now;
    }
    await db.collections.update(collection.id, collectionUpdates);
  }

  async getCollectionPageMetadata(collectionId: string, sectionId?: string) {
    const collection = await this.getCollectionById(collectionId);
    if (!collection) return [];
    const query = db.pagesMetadata
      .where('collectionId')
      .equals(collectionId)
      .and(page => !page.archivedAt);

    if (sectionId) {
      query.and(page => page.sectionId === sectionId);
    }

    return query.sortBy('orderNumber');
  }

  searchCollections(searchQuery: string) {
    return db.collections
      .where('archivedAt')
      .equals(0)
      .and(collection => collection.title.toLowerCase().includes(searchQuery.toLowerCase()))
      .reverse()
      .sortBy('updatedAt');
  }

  updateCollection(collectionId: string, collection: Partial<Collection>) {
    return db.collections.update(collectionId, { ...collection, updatedAt: this.now });
  }

  bulkUpsertCollections(collections: Collection[]) {
    return db.collections.bulkPut(collections);
  }

  bulkDeleteCollections(collections: Collection[]) {
    return db.collections.bulkDelete(collections.map(c => c.id));
  }
}

const DBService = new LocalDBService();
export default DBService;
