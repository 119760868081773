import { Loader } from 'lucide-react';

const Spinner = ({ size = 20 }) => {
  return (
    <div className="flex items-center justify-center">
      <Loader size={size} className={`text-foreground animate-spin`} />
    </div>
  );
};
export default Spinner;
