import { Button } from '@/components/ui/button';
import { useCollections } from '@/hooks/useCollections';
import { usePages } from '@/hooks/usePages';
import { useCollectionStore } from '@/stores/useCollectionStore';
import { DisplayedCollectionSection, Page, Section } from '@/types';
import { Plus } from 'lucide-react';
import { nanoid } from 'nanoid';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const SidebarHeader = () => {
  return (
    <div className="w-full flex items-center justify-between mt-3 mb-3 px-1">
      <h2 className="text-sm font-[14px] pl-2">Sections</h2>
      <div className="flex gap-1">
        <AddSection />
      </div>
    </div>
  );
};

export default memo(SidebarHeader);

const AddSection = () => {
  const navigate = useNavigate();
  const currentCollection = useCollectionStore(state => state.currentCollection);
  const collectionStructure = useCollectionStore(state => state.collectionStructure);
  const { addPage } = usePages();
  const updateStructureItem = useCollections().updateStructureItem;

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const sectionId = nanoid();
      const pageInfo: Partial<Page> = { sectionId };
      if (currentCollection) {
        pageInfo.collectionId = currentCollection.id;
      }
      const newPage = await addPage({ sectionId, collectionId: currentCollection?.id });
      const newSection: Section = {
        id: sectionId,
        title: `Section ${String(collectionStructure.length + 1)}`,
        orderNumber: collectionStructure.length + 1,
      };
      const structureItem: DisplayedCollectionSection = {
        section: {
          ...newSection,
          pages: [],
        },
      };
      if (newPage && currentCollection?.id) {
        navigate(`/collection/${currentCollection.id}/page/${newPage}`);
      }
      await updateStructureItem([...collectionStructure, structureItem]);
    },
    [addPage, collectionStructure, currentCollection?.id, navigate, updateStructureItem]
  );

  return (
    <div>
      <Button
        onClick={handleSubmit}
        variant="ghost"
        size="icon"
        className="p-0 m-0 h-8 w-8 outline-none"
      >
        <Plus className="h-4 w-4" />
      </Button>
    </div>
  );
};
