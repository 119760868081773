import { ReactNode } from 'react';
import { useAppInitializer } from '@/hooks/useAppInitializer';

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  useAppInitializer();

  return <>{children}</>;
};
