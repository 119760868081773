import { create } from 'zustand';
import { Page, PageMetadata, OrderOption } from '@/types';

type PageState = {
  pagesMetadata: PageMetadata[];
  currentPage: Page | null;
  orderedBy: OrderOption;
  archivedPages: PageMetadata[];
  loading: boolean;
  isDirty: boolean;
  editable: boolean;

  // Simple actions (setters)
  setLoading: (loading: boolean) => void;
  setIsDirty: (isDirty: boolean) => void;
  setEditable: (editable: boolean) => void;
  setPagesMetadata: (metadata: PageMetadata[]) => void;
  setCurrentPage: (page: Page | null) => void;
  setOrderedBy: (orderBy: OrderOption) => void;
  setArchivedPages: (pages: PageMetadata[]) => void;
};

// Initialize with stored preferences if available
const getInitialOrderBy = (): OrderOption => {
  if (typeof localStorage !== 'undefined') {
    return (localStorage.getItem('order-by') || 'updatedAt') as OrderOption;
  }
  return 'updatedAt';
};

const getInitialEditable = (): boolean => {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem('editable') !== 'false';
  }
  return true;
};

export const usePageStore = create<PageState>(set => ({
  // Initial state
  pagesMetadata: [],
  currentPage: null,
  orderedBy: getInitialOrderBy(),
  archivedPages: [],
  loading: true,
  isDirty: false,
  editable: getInitialEditable(),

  // Simple setters
  setLoading: loading => {
    set({ loading });
  },
  setIsDirty: isDirty => {
    set({ isDirty });
  },
  setEditable: editable => {
    set({ editable });
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('editable', String(editable));
    }
  },
  setPagesMetadata: metadata => {
    set({ pagesMetadata: metadata });
  },
  setCurrentPage: page => {
    set({ currentPage: page });
  },
  setOrderedBy: orderBy => {
    set({ orderedBy: orderBy });
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('order-by', orderBy);
    }
  },
  setArchivedPages: pages => {
    set({ archivedPages: pages });
  },
}));
