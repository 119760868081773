import { ThemeToggle } from '../MenuBar/ThemeToggle';
import { useRef } from 'react';
import ArchivedIndicator from '../MenuBar/ArchivedIndicator';
import { SyncStatus } from '../MenuBar/SyncStatus';

const CollectionMenu = () => {
  const menuRef = useRef<HTMLElement | null>(null);

  return (
    <nav ref={menuRef} className="sticky z-50 top-0 w-full bg-background">
      <div className="flex items-center justify-end p-2 pr-3 w-full gap-2">
        <SyncStatus />
        <ThemeToggle />
      </div>
      <ArchivedIndicator />
    </nav>
  );
};

export default CollectionMenu;
