import { create } from 'zustand';
import { Collection, DisplayedCollectionSection, PageMetadata } from '@/types';

type CollectionState = {
  collections: Collection[];
  currentCollection: Collection | null;
  collectionStructure: DisplayedCollectionSection[];
  getCurrentCollectionPageMetadata: () => PageMetadata[];

  // Simple actions (setters)
  setCollections: (collections: Collection[]) => void;
  setCurrentCollection: (collection: Collection | null) => void;
  setCollectionStructure: (structure: DisplayedCollectionSection[]) => void;
  updateCollection: (id: string, update: Partial<Collection>) => void;
};

export const useCollectionStore = create<CollectionState>((set, get) => ({
  // Initial state
  collections: [],
  currentCollection: null,
  collectionStructure: [],

  getCurrentCollectionPageMetadata: () => {
    return get()
      .collectionStructure.map(c => c.section.pages)
      .flat();
  },

  // Simple setters
  setCollections: collections => {
    set({ collections });
  },

  setCurrentCollection: collection => {
    set({ currentCollection: collection });
  },

  setCollectionStructure: structure => {
    set({ collectionStructure: structure });
  },

  updateCollection: (id, update) => {
    set(state => ({
      collections: state.collections.map(c => (c.id === id ? { ...c, ...update } : c)),
      currentCollection:
        state.currentCollection?.id === id
          ? { ...state.currentCollection, ...update }
          : state.currentCollection,
    }));
  },
}));
