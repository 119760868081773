import { useEffect } from 'react';
import { useModal } from '@/providers/ModalProvider';
import { useSyncContext } from '@/providers/SyncProvider';
import { useNavigate } from 'react-router-dom';
import { useCollectionStore } from '@/stores/useCollectionStore';
import { usePages } from './usePages';

export const useKeyboardCommands = () => {
  const navigate = useNavigate();
  const { toggleModal } = useModal();
  const { syncLoading, handleSync } = useSyncContext();
  const currentCollection = useCollectionStore(state => state.currentCollection);
  const { addPage } = usePages();

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key.toLocaleLowerCase() === 's' && (e.metaKey || e.ctrlKey) && !syncLoading) {
        e.preventDefault();
        void handleSync();
      }
      if (e.key.toLocaleLowerCase() === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        toggleModal('search');
      }
    };

    document.addEventListener('keydown', down);
    return () => {
      document.removeEventListener('keydown', down);
    };
  }, [addPage, currentCollection?.id, handleSync, navigate, syncLoading, toggleModal]);
};
