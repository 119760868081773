import React, { useState } from 'react';
import { CustomModal } from '../ui/modal';
import { SearchModalContent } from '../ui/Search';
import { useSearchPage } from '@/hooks/useSearchPage';
import ActionLinkButton from '../ui/ActionLinkButton';
import { SearchIcon } from 'lucide-react';
import { usePages } from '@/hooks/usePages';
import { usePageStore } from '@/stores/usePageStore';
import { useCollectionStore } from '@/stores/useCollectionStore';

export default function SearchButton() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <ActionLinkButton
        Icon={SearchIcon}
        title={'Search'}
        onClick={() => {
          setIsModalOpen(true);
        }}
      />
      <SearchModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
}

export const SearchModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const currentPage = usePageStore(state => state.currentPage);
  const pagesMetadata = usePageStore(state => state.pagesMetadata);
  const getCurrentCollectionPageMetadata = useCollectionStore(
    state => state.getCurrentCollectionPageMetadata
  );
  const currentCollectionPageMetadata = getCurrentCollectionPageMetadata();

  const { searchPages } = usePages();
  const { searchQuery, searchResults, setSearchQuery, onClickPage } = useSearchPage({
    searchFn: searchPages,
    defaultResults: currentPage?.collectionId ? currentCollectionPageMetadata : pagesMetadata,
    collectionId: currentPage?.collectionId || '',
  });

  const closeModal = () => {
    setSearchQuery('');
    onClose();
  };

  return (
    <CustomModal isOpen={isOpen} onClose={closeModal}>
      <SearchModalContent
        searchQuery={searchQuery}
        searchResults={searchResults}
        onSearchChange={setSearchQuery}
        onClickPage={onClickPage}
      />
    </CustomModal>
  );
};
