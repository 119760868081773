import { useCallback } from 'react';
import { usePageStore } from '../stores/usePageStore';
import { useCollectionStore } from '../stores/useCollectionStore';
import { Page, OrderOption } from '@/types';
import pageRepository from '@/services/database/indexDB/PageRepository';
import collectionRepository from '@/services/database/indexDB/CollectionRepository';
import { useTagStore } from '../stores/useTagStore';
import { db } from '@/services/database/database';
import { useCollections } from './useCollections';

export function usePages() {
  // Get state and actions from stores
  const {
    currentPage,
    editable,
    pagesMetadata,
    orderedBy,
    setCurrentPage,
    setPagesMetadata,
    setArchivedPages,
    setLoading,
    setIsDirty,
    setOrderedBy,
  } = usePageStore();

  const { activeFilters } = useTagStore();

  const { loadCollectionStructure } = useCollections();

  // Load pages based on filters and order
  const loadPages = useCallback(
    async (order?: OrderOption) => {
      setLoading(true);
      const defaultOrder = order || orderedBy;
      const pageMetadata = await pageRepository.getPagesMetadata(defaultOrder, activeFilters);
      setPagesMetadata(pageMetadata);
      setLoading(false);
    },
    [activeFilters, orderedBy, setLoading, setPagesMetadata]
  );

  // Add a new page
  const addPage = useCallback(
    async (pageData?: Partial<Page>) => {
      try {
        const pageId = await pageRepository.addPage(pageData);

        if (pageData?.collectionId) {
          await collectionRepository.updateCollection(pageData.collectionId, {
            updatedAt: Date.now(),
          });
          await loadCollectionStructure(pageData.collectionId);
        } else {
          await loadPages();
        }

        return pageId;
      } catch (error) {
        console.warn('Error adding page:', error);
        return undefined;
      }
    },
    [loadPages, loadCollectionStructure]
  );

  // Update the current page title
  const updatePageTitle = useCallback(
    async (id: string, title: string) => {
      if (!editable) return;

      await pageRepository.updatePage(id, { title });
      setIsDirty(true);

      if (currentPage?.collectionId) {
        await loadCollectionStructure(currentPage.collectionId);
        void collectionRepository.updateCollection(currentPage.collectionId, {
          updatedAt: Date.now(),
        });
      } else {
        setPagesMetadata(pagesMetadata.map(p => (p.id === id ? { ...p, title } : p)));
      }

      if (currentPage && currentPage.id === id) {
        setCurrentPage({
          ...currentPage,
          title,
          updatedAt: Date.now(),
        });
      }
    },
    [
      currentPage,
      editable,
      loadCollectionStructure,
      pagesMetadata,
      setCurrentPage,
      setIsDirty,
      setPagesMetadata,
    ]
  );

  // Update the current page content
  const updateCurrentPageContent = useCallback(
    (content: string) => {
      if (!currentPage || !editable) return;

      const isSame = content === currentPage.content;
      if (isSame) return;

      const newCurrentPage = { ...currentPage, content, updatedAt: Date.now() };
      setCurrentPage(newCurrentPage);
      void pageRepository.updatePage(currentPage.id, newCurrentPage);

      const needsRefresh =
        (pagesMetadata[0]?.id !== currentPage.id || usePageStore.getState().isDirty) &&
        !currentPage.archivedAt;

      if (needsRefresh) {
        void loadPages();
      }

      const updateCollectionUpdatedAt = !currentPage.archivedAt && currentPage.collectionId;
      if (updateCollectionUpdatedAt) {
        void collectionRepository.updateCollection(currentPage.collectionId as string, {
          updatedAt: Date.now(),
        });
        void loadCollectionStructure(currentPage.collectionId);
      }

      setIsDirty(true);
    },
    [
      currentPage,
      editable,
      loadCollectionStructure,
      loadPages,
      pagesMetadata,
      setCurrentPage,
      setIsDirty,
    ]
  );

  // Delete a page
  const deletePage = useCallback(
    async (id: string) => {
      await pageRepository.deletePage(id);
      setArchivedPages(usePageStore.getState().archivedPages.filter(p => p.id !== id));
    },
    [setArchivedPages]
  );

  // Set the current page by ID
  const setCurrentPageId = useCallback(
    async (id?: string | null) => {
      if (id === null) {
        setCurrentPage(null);
        useCollectionStore.getState().setCollectionStructure([]);
        return null;
      }

      setLoading(true);

      if (id) {
        const page = await pageRepository.getPageById(id);
        if (page) {
          setCurrentPage(page);
          setLoading(false);
          return id;
        } else {
          setCurrentPage(null);
          setLoading(false);
          return null;
        }
      }

      // No ID provided, load the first page
      const allPages = await pageRepository.getAllPages(orderedBy);
      if (allPages.length) {
        setCurrentPage(allPages[0]);
        setLoading(false);
        return allPages[0].id;
      }

      // No pages exist, add default pages
      await pageRepository.addDefaultPages();
      const pages = await pageRepository.getAllPages(orderedBy);
      if (pages.length) {
        await loadPages('orderNumber');
        setCurrentPage(pages[0]);
        setLoading(false);
        return pages[0].id;
      }

      setLoading(false);
      return null;
    },
    [loadPages, orderedBy, setCurrentPage, setLoading]
  );

  // Refresh the current page
  const refreshCurrentPage = useCallback(async () => {
    const pathParts = window.location.pathname.split('/');
    const pageId = pathParts.pop();

    if (pageId) {
      const page = await pageRepository.getPageById(pageId);
      if (!page) return;
      setCurrentPage(page);
    } else {
      const allPages = await pageRepository.getAllPages(orderedBy);
      if (allPages.length) {
        setCurrentPage(allPages[0]);
      }
    }
  }, [orderedBy, setCurrentPage]);

  // Toggle page public status
  const toggleIsPublic = useCallback(
    async (id: string, isPublic: boolean) => {
      if (!currentPage) return;

      setCurrentPage({ ...currentPage, isPublic });
      await pageRepository.updateIsPublic(id, isPublic);
      await loadPages();
      setIsDirty(true);
    },
    [currentPage, loadPages, setCurrentPage, setIsDirty]
  );

  // Archive a page
  const archivePage = useCallback(
    async (id: string, collectionId?: string) => {
      await pageRepository.archivePage(id);

      if (collectionId) {
        await loadCollectionStructure(collectionId);
      } else {
        await loadPages();
      }

      if (currentPage && currentPage.id === id) {
        const now = Date.now();
        setCurrentPage({
          ...currentPage,
          archivedAt: now,
          updatedAt: now,
        });
      }

      setIsDirty(true);
    },
    [currentPage, loadCollectionStructure, loadPages, setCurrentPage, setIsDirty]
  );

  // Unarchive a page
  const unarchivePage = useCallback(
    async (id: string) => {
      await pageRepository.unarchivePage(id);
      const page = await pageRepository.getPageById(id);

      if (!page) return;

      if (currentPage && currentPage.id === id) {
        setCurrentPage({ ...currentPage, archivedAt: 0 });
      }

      setArchivedPages(usePageStore.getState().archivedPages.filter(p => p.id !== id));

      if (!page.collectionId) {
        void loadPages();
        return;
      }

      const collection = await collectionRepository.getCollectionById(page.collectionId);
      const collectionSections = (collection?.sections || []).map(s => {
        if (s.id === page.sectionId && s.archivedAt) {
          s.archivedAt = undefined;
        }
        return s;
      });

      await collectionRepository.updateCollection(page.collectionId, {
        sections: collectionSections,
      });

      await loadCollectionStructure(page.collectionId);
      setIsDirty(true);
    },
    [currentPage, loadCollectionStructure, loadPages, setArchivedPages, setCurrentPage, setIsDirty]
  );

  // Get all pages
  const getPages = useCallback(async () => {
    await pageRepository.cleanupOldArchivedPages();
    const results = db.pages.reverse().sortBy('updatedAt');
    return results as Promise<Page[]>;
  }, []);

  // Search pages
  const searchPages = useCallback(
    async (query: string, archived = false, collectionId?: string) => {
      return pageRepository.searchPages(query, archived, collectionId);
    },
    []
  );

  // Get archived pages
  const getArchivedPages = useCallback(
    async (collectionId?: string) => {
      const archived = await pageRepository.getArchivedPages(collectionId);
      setArchivedPages(archived);
    },
    [setArchivedPages]
  );

  // Search archived pages
  const searchArchivedPages = useCallback(
    async (query: string, collectionId?: string) => {
      const archived = true;
      const searchResults = await pageRepository.searchPages(query, archived, collectionId);
      setArchivedPages(searchResults);
    },
    [setArchivedPages]
  );

  // Sync remote with local
  const syncRemoteWithLocal = useCallback(
    async (notesToAdd: Page[], notesToUpdate: Page[], toDeleteFromLocal: Page[]) => {
      // Perform batch updates
      if (notesToUpdate.length > 0) {
        await pageRepository.bulkUpsertPages(notesToUpdate);

        // Ensure current page is refreshed
        if (currentPage?.id) {
          const updatedCurrentPage = notesToUpdate.find(p => p.id === currentPage.id);
          if (updatedCurrentPage) {
            setCurrentPage(updatedCurrentPage);
          }
        }
      }

      // Add new pages
      if (notesToAdd.length > 0) {
        await pageRepository.bulkUpsertPages(notesToAdd);
      }

      // Archive deleted ones
      if (toDeleteFromLocal.length) {
        const archived = toDeleteFromLocal.map(p => ({
          ...p,
          archivedAt: p.archivedAt || Date.now(),
          updatedAt: Date.now(),
        }));
        await pageRepository.bulkUpsertPages(archived);
      }

      await loadPages();
    },
    [currentPage, loadPages, setCurrentPage]
  );

  // Update order preference
  const updateOrderBy = useCallback(
    (orderOption: OrderOption) => {
      setOrderedBy(orderOption);
    },
    [setOrderedBy]
  );

  return {
    loadPages,
    addPage,
    updatePageTitle,
    updateCurrentPageContent,
    deletePage,
    setCurrentPageId,
    refreshCurrentPage,
    toggleIsPublic,
    archivePage,
    unarchivePage,
    getPages,
    searchPages,
    getArchivedPages,
    searchArchivedPages,
    syncRemoteWithLocal,
    updateOrderBy,
  };
}
