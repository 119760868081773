import { nanoid } from 'nanoid';
import { subWeeks } from 'date-fns';
import { OrderOption, Page, PageMetadata } from '@/types';
import { db } from './database';
import { INITIAL_PAGES } from '@/services/database/defaultData/defaultNotes';
import { BaseRepository } from './BaseRepository';

export class PageRepository extends BaseRepository {
  async getNotesSince(syncTime: number | null) {
    const notes = await db.pages
      .where('updatedAt')
      .above(syncTime || 0)
      .toArray();

    return notes.map(n => ({
      ...n,
      createdAt: ((n.createdAt / 1000) | 0) * 1000,
      updatedAt: ((n.updatedAt / 1000) | 0) * 1000,
      archivedAt: n.archivedAt ? ((n.archivedAt / 1000) | 0) * 1000 : 0,
    }));
  }

  async getAllPages(orderBy: OrderOption = 'updatedAt'): Promise<Page[]> {
    if (orderBy !== 'orderNumber') {
      return db.pages.where('archivedAt').equals(0).reverse().sortBy(orderBy);
    }
    return db.pages.where('archivedAt').equals(0).sortBy(orderBy);
  }

  async getPagesMetadata(
    orderBy: OrderOption = 'updatedAt',
    filters: string[] = []
  ): Promise<PageMetadata[]> {
    let query = db.pagesMetadata
      .where('archivedAt')
      .equals(0)
      .and(page => !page.collectionId);
    if (filters.length) {
      query = query.and(page => {
        if (!page.tags) return false;
        const filterSet = new Set(filters);
        return page.tags.some(tag => filterSet.has(tag));
      });
    }
    const pages = await (orderBy === 'orderNumber'
      ? query.sortBy(orderBy)
      : query.reverse().sortBy(orderBy));

    return pages;
  }

  async getPageById(id: string): Promise<Page | undefined> {
    return db.pages.get(id);
  }

  async addPage(pageData?: Partial<Page>): Promise<string> {
    const pageId = pageData?.id || nanoid();
    const metadata: PageMetadata = {
      id: pageId,
      title: pageData?.title || 'Untitled',
      createdAt: this.now,
      updatedAt: this.now,
      archivedAt: 0,
      isPublic: pageData?.isPublic || false,
      tags: pageData?.tags || [],
      // for collection related page metadata
      collectionId: pageData?.collectionId || '',
      sectionId: pageData?.sectionId || '',
      orderNumber: pageData?.orderNumber || 0,
    };
    const newPage = { ...metadata, content: pageData?.content || '' };

    const exists = await db.pages.get(pageId);
    if (exists) return pageId;

    await Promise.all([db.pages.add(newPage), db.pagesMetadata.add(metadata)]);
    return pageId;
  }

  async addDefaultPages() {
    const hasPages = await db.pages.count();
    if (hasPages) {
      return;
    }
    for (const p of INITIAL_PAGES) {
      await this.addPage({
        title: p.title,
        content: p.content ? JSON.stringify(p.content) : '',
      });
    }
  }

  async updatePage(id: string, patch: Partial<Page>) {
    const metadata = { ...patch };
    if (metadata.content) {
      delete metadata.content;
    }
    const updatedAt = this.now;
    await Promise.all([
      db.pagesMetadata.update(id, { ...metadata, updatedAt }),
      db.pages.update(id, { ...patch, updatedAt }),
    ]);
  }

  async updateIsPublic(id: string, isPublic: boolean) {
    return this.updatePage(id, { isPublic: isPublic });
  }

  async archivePage(id: string) {
    return this.updatePage(id, { archivedAt: Date.now() });
  }

  async unarchivePage(id: string) {
    await this.updatePage(id, { archivedAt: 0 });
    const page = await db.pages.get(id);
    return page;
  }

  async deletePage(id: string): Promise<void> {
    await Promise.all([db.pages.delete(id), db.pagesMetadata.delete(id)]);
  }

  async searchPages(
    query: string,
    archived: boolean = false,
    collectionId?: string
  ): Promise<PageMetadata[]> {
    const lowercaseQuery = query.toLowerCase();

    let pagesQuery = db.pagesMetadata.reverse();
    pagesQuery = pagesQuery.and(page => (archived ? !!page.archivedAt : !page.archivedAt));
    if (collectionId) {
      pagesQuery = pagesQuery.and(page => page.collectionId === collectionId);
    }
    pagesQuery = pagesQuery.and(page => page.title.toLowerCase().includes(lowercaseQuery));
    return await pagesQuery.sortBy('updatedAt');
  }

  async getArchivedPages(collectionId?: string): Promise<PageMetadata[]> {
    const query = db.pagesMetadata.where('archivedAt').notEqual(0);

    if (collectionId) {
      query.and(page => page.collectionId === collectionId);
    }
    return query.reverse().sortBy('updatedAt');
  }

  async cleanupOldArchivedPages(): Promise<void> {
    const oneWeekAgo = subWeeks(new Date(), 1).getTime();
    await db.pages
      .where('archivedAt')
      .notEqual(0)
      .and(page => !page.archivedAt || page.archivedAt < oneWeekAgo)
      .delete();
  }

  async bulkUpsertPages(pages: Page[]): Promise<void> {
    await Promise.all([db.pagesMetadata.bulkPut(pages), db.pages.bulkPut(pages)]);
  }

  async bulkDeletePages(pages: Page[]): Promise<void> {
    await Promise.all([
      db.pagesMetadata.bulkDelete(pages.map(p => p.id)),
      db.pages.bulkDelete(pages.map(p => p.id)),
    ]);
  }
}

export default new PageRepository();
