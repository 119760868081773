import { useSyncContext } from '@/providers/SyncProvider';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { useMemo } from 'react';
import { LogOut, User } from 'lucide-react';
import { Link } from 'react-router-dom';

const Header = () => {
  const { user, authLoading, signOut, syncLoading } = useSyncContext();

  const { initials, firstName } = useMemo(() => {
    if (!user?.displayName) return { initials: '', firstName: '' };
    const initals = user.displayName
      .split('@')[0]
      .split('.')
      .map(part => part[0])
      .join('')
      .toUpperCase();
    const name = user.displayName.split(' ')[0];
    return { initials: initals, firstName: name };
  }, [user]);

  if (!user || authLoading) {
    return (
      <Link
        to="/collections"
        className="w-full font-semibold flex mt-5 mb-4 pl-4 text-lg text-center whitespace-nowrap"
      >
        Andika {!authLoading ? 'Offline' : ''}
      </Link>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={syncLoading} className="max-h-16">
        <button className="flex items-center gap-2 py-4 px-2 flex-1 w-full">
          <Avatar className="h-6 w-6">
            {user.photoURL && window.location.hostname !== 'localhost' ? (
              <img src={user.photoURL} alt={firstName || 'User'} className="h-6 w-6 rounded-full" />
            ) : (
              <AvatarFallback className="font-bold text-[16px]">
                {user.displayName ? initials : user.email?.[0].toUpperCase()}
              </AvatarFallback>
            )}
          </Avatar>
          <span className="font-semibold text-[16px] truncate">
            {firstName ? `${firstName}'s Workspace` : 'Andika Online'}
          </span>
        </button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start" className="w-[300px] z-[9999]">
        <DropdownMenuLabel className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <User className="h-4 w-4 text-muted-foreground" />
            <span className="text-sm font-normal text-muted-foreground">Personal Account</span>
          </div>
          <span className="text-sm truncate font-normal">{user.email}</span>
        </DropdownMenuLabel>

        <DropdownMenuItem
          className="text-red-600 focus:text-red-600 flex items-center gap-2 cursor-pointer py-3"
          onClick={signOut}
        >
          <LogOut className="h-4 w-4" />
          <span>Sign out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Header;
