import { INITIAL_PAGES } from '@/services/database/defaultData/defaultNotes';
import { Page } from '@/types';
import { isBefore, subWeeks } from 'date-fns';

const CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID as string;

export interface User {
  id?: string;
  sub?: string;
  name: string;
  email: string;
  picture: string;
}

export interface TokenResponse {
  access_token: string;
}

interface GoogleAccountsId {
  initialize: (config: {
    client_id: string;
    callback: (response: { credential: string }) => void;
    scope: string;
  }) => void;
}

interface GoogleAccountsOAuth2 {
  initTokenClient: (config: {
    client_id: string;
    scope: string;
    callback: (tokenResponse: { access_token: string }) => void;
  }) => {
    requestAccessToken: () => void;
  };
}

interface GoogleAccounts {
  id: GoogleAccountsId;
  oauth2: GoogleAccountsOAuth2;
}

interface WindowWithGoogle extends Window {
  google: {
    accounts: GoogleAccounts;
  };
}

export const initializeGoogleSignIn = (
  handleCredentialResponse: (response: { credential: string }) => void,
  handleTokenResponse: (tokenResponse: { access_token: string }) => void
) => {
  const googleWindow = window as unknown as WindowWithGoogle;

  googleWindow.google.accounts.id.initialize({
    client_id: CLIENT_ID,
    callback: handleCredentialResponse,
    scope: 'https://www.googleapis.com/auth/drive.file',
  });

  googleWindow.google.accounts.oauth2.initTokenClient({
    client_id: CLIENT_ID,
    scope: 'https://www.googleapis.com/auth/drive.file',
    callback: handleTokenResponse,
  });
};

export const requestAccessToken = (
  handleTokenResponse: (tokenResponse: { access_token: string }) => void
) => {
  const googleWindow = window as unknown as WindowWithGoogle;

  googleWindow.google.accounts.oauth2
    .initTokenClient({
      client_id: CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/drive.file',
      callback: handleTokenResponse,
    })
    .requestAccessToken();
};

export const fetchUserInfo = async (token: string) => {
  const response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response.ok) {
    return response.json() as Promise<User>;
  }
  throw new Error('Failed to fetch user info');
};

export const searchForBackupFile = async (accessToken: string) => {
  const response = await fetch(
    'https://www.googleapis.com/drive/v3/files?q=name%3D%27Andika_Notes_Backup.json%27&fields=files(id%2C%20modifiedTime)',
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
  return response.json() as {
    files?: Array<{ id: string; modifiedTime: string }>;
  };
};

export const downloadBackupFile = async (fileId: string, accessToken: string) => {
  const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (response.ok) {
    return response.text();
  }
  throw new Error('Failed to download backup file');
};

export const uploadBackupFile = async (
  fileContent: string,
  fileId: string | null,
  accessToken: string
) => {
  const file = new Blob([fileContent], { type: 'application/json' });
  const metadata = { name: 'Andika_Notes_Backup.json', mimeType: 'application/json' };

  const form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
  form.append('file', file);

  const uploadUrl = fileId
    ? `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=multipart`
    : 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart';

  const response = await fetch(uploadUrl, {
    method: fileId ? 'PATCH' : 'POST',
    headers: { Authorization: `Bearer ${accessToken}` },
    body: form,
  });

  if (!response.ok) {
    throw new Error('Failed to upload file');
  }
};

export type SyncResult = {
  mergedNotesList: Page[];
  toAddInLocal: Page[];
  toUpdateInLocal: Page[];
  toDeleteFromLocal: Page[];
};

export const compareAndMergeNotes = (
  localNotes: Page[],
  remoteNotes: Page[],
  lastRemoteSyncTime: number | null
): SyncResult => {
  const mergedNotesList: Page[] = [];
  const toAddInLocal: Page[] = [];
  const toUpdateInLocal: Page[] = [];
  const toDeleteFromLocal: Page[] = [];

  const localNotesMap = new Map(localNotes.map(note => [note.id, note]));
  const remoteNotesMap = new Map(
    remoteNotes.filter(n => !hasExceededArchivedDate(n)).map(note => [note.id, note])
  );

  // Process all notes from both local and remote
  const allNoteIds = new Set([...localNotesMap.keys(), ...remoteNotesMap.keys()]);

  allNoteIds.forEach(id => {
    const localNote = localNotesMap.get(id);
    const remoteNote = remoteNotesMap.get(id);

    if (localNote && remoteNote) {
      // Note exists in both local and remote
      if (remoteNote.updatedAt > localNote.updatedAt) {
        if (remoteNote.archivedAt !== 0) {
          // Remote note is archived (soft-deleted)
          toDeleteFromLocal.push(remoteNote);
        } else {
          // The remote are the latest
          mergedNotesList.push(remoteNote);
          toUpdateInLocal.push(remoteNote);
        }
      } else if (
        INITIAL_PAGES.map(p => p.id).includes(localNote.id) &&
        localNote.createdAt === localNote.updatedAt
      ) {
        // Maintain the initial pages. In this case the local inital pages are the fresh ones from app creation
        toUpdateInLocal.push(remoteNote);
      } else {
        // Local note is more recent or equal
        mergedNotesList.push(localNote);
      }
    } else if (localNote) {
      // Note only exists locally
      if (!lastRemoteSyncTime || localNote.updatedAt > lastRemoteSyncTime) {
        // Local note was created or updated since last sync
        mergedNotesList.push(localNote);
      } else {
        // Local note was likely deleted on remote, don't include in merged list
        toDeleteFromLocal.push(localNote);
      }
    } else if (remoteNote) {
      // Note only exists remotely
      mergedNotesList.push(remoteNote);
      toAddInLocal.push(remoteNote);
    }
  });

  return {
    mergedNotesList,
    toAddInLocal,
    toUpdateInLocal,
    toDeleteFromLocal,
  };
};

function hasExceededArchivedDate(note: Page) {
  const oneWeekAgo = subWeeks(new Date(), 1);
  return !!note.archivedAt && isBefore(new Date(note.archivedAt), oneWeekAgo);
}
