import ListContainer from './ListContainer';
import { useCallback, useEffect, useState } from 'react';

const PageList = () => {
  const [containerHeight, setContainerHeight] = useState('calc(100vh - 400px)');

  const calculateContainerHeight = useCallback(() => {
    const logoHeight = 80; // Logo + padding
    const headerHeight = 80; // Notes header + padding
    const footerHeight = 220; // Footer items + padding
    const totalPresetHeight = logoHeight + headerHeight + footerHeight;

    return `calc(100vh - ${String(totalPresetHeight)}px)`;
  }, []);

  useEffect(() => {
    setContainerHeight(calculateContainerHeight());

    const handleResize = () => {
      setContainerHeight(calculateContainerHeight());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateContainerHeight]);

  return (
    <div
      className="flex flex-col items-start space-y-1 relative shadow-inner-1"
      style={{ maxHeight: containerHeight, overflowY: 'auto' }}
    >
      <ListContainer />
    </div>
  );
};

export default PageList;
