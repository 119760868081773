import { useNavigate } from 'react-router-dom';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useCollectionStore } from '@/stores/useCollectionStore';
import { usePages } from '@/hooks/usePages';
import { useCollections } from '@/hooks/useCollections';
import NestedListContainer from './NestedListContainer';

const CollectionPageList = () => {
  const navigate = useNavigate();
  const currentCollection = useCollectionStore(state => state.currentCollection);
  const collectionStructure = useCollectionStore(state => state.collectionStructure);
  const { addPage, archivePage } = usePages();
  const { updateStructureItem } = useCollections();

  const [containerHeight, setContainerHeight] = useState('calc(100vh - 400px)');

  const calculateContainerHeight = useCallback(() => {
    const logoHeight = 80; // Logo + padding
    const headerHeight = 80; // Notes header + padding
    const footerHeight = 60; // Footer items + padding
    const totalPresetHeight = logoHeight + headerHeight + footerHeight;

    return `calc(100vh - ${String(totalPresetHeight)}px)`;
  }, []);

  useEffect(() => {
    setContainerHeight(calculateContainerHeight());

    const handleResize = () => {
      setContainerHeight(calculateContainerHeight());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const key = useMemo(
    () =>
      collectionStructure.length > 0
        ? collectionStructure
            .map(
              s =>
                s.section.pages.map(p => `${p.title}_${String(p.updatedAt)}`).join('') +
                s.section.title +
                String(s.section.orderNumber)
            )
            .join('')
        : '',
    [collectionStructure]
  );

  // onRenameSection: (section: Section) => void;
  const onAddSectionPage = async (sectionId: string, orderNumber: number) => {
    const collectionId = currentCollection?.id as string;
    const pageData = { sectionId, collectionId: collectionId, orderNumber };
    const newPageId = await addPage(pageData);
    if (newPageId) {
      navigate(`/collection/${collectionId}/page/${newPageId}`);
    }
  };
  const onArchiveSection = async (sectionId: string) => {
    const archivedSection = collectionStructure.find(s => s.section.id === sectionId);
    const newStructure = collectionStructure.filter(s => s.section.id !== sectionId);
    if (archivedSection?.section.pages.length) {
      await Promise.all(archivedSection.section.pages.map(p => archivePage(p.id)));
    }
    await updateStructureItem(newStructure);
  };

  if (collectionStructure.length === 0) {
    return null;
  }

  return (
    <div
      className="flex flex-col items-start space-y-1 relative"
      style={{ maxHeight: containerHeight, overflowY: 'auto' }}
    >
      {collectionStructure.length === 0 && (
        <span className="text-sm text-muted-foreground px-4 pt-3">Add more notes here</span>
      )}
      <NestedListContainer
        key={key}
        displayedSections={collectionStructure}
        disableDraggable={false}
        onUpdate={updateStructureItem}
        onAddSectionPage={onAddSectionPage}
        onArchiveSection={onArchiveSection}
      />
    </div>
  );
};

export default memo(CollectionPageList);
