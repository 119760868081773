import { useCallback } from 'react';
import { useTagStore } from '../stores/useTagStore';
import { usePageStore } from '../stores/usePageStore';
import tagRepository from '@/services/database/indexDB/TagRepository';
import pageRepository from '@/services/database/indexDB/PageRepository';

export function useTags() {
  // Get state from stores
  const { setTags, toggleFilter, clearFilters } = useTagStore();

  const { currentPage, setCurrentPage } = usePageStore();

  // Refresh tags
  const refreshTags = useCallback(async () => {
    const fetchedTags = await tagRepository.getTags();
    setTags(fetchedTags);
  }, [setTags]);

  // Assign a tag to the current page
  const assignTag = useCallback(
    async (tag: string) => {
      if (!currentPage?.id) return;

      await tagRepository.assignTag(currentPage.id, tag);
      await refreshTags();

      // Refresh current page to reflect tag changes
      const updatedPage = await pageRepository.getPageById(currentPage.id);
      if (updatedPage) {
        setCurrentPage(updatedPage);
      }
    },
    [currentPage, refreshTags, setCurrentPage]
  );

  // Unassign a tag from the current page
  const unassignTag = useCallback(
    async (tag: string) => {
      if (!currentPage?.id) return;

      await tagRepository.unassignTag(currentPage.id, tag);
      await refreshTags();

      // Refresh current page to reflect tag changes
      const updatedPage = await pageRepository.getPageById(currentPage.id);
      if (updatedPage) {
        setCurrentPage(updatedPage);
      }
    },
    [currentPage, refreshTags, setCurrentPage]
  );

  // Update/rename a tag
  const updateTag = useCallback(
    async (oldTag: string, newTag: string) => {
      await tagRepository.editTag(oldTag, newTag);
      await refreshTags();

      // Refresh current page if it has the tag
      if (currentPage?.tags?.includes(oldTag)) {
        const updatedPage = await pageRepository.getPageById(currentPage.id);
        if (updatedPage) {
          setCurrentPage(updatedPage);
        }
      }
    },
    [currentPage, refreshTags, setCurrentPage]
  );

  // Delete a tag
  const deleteTag = useCallback(
    async (tag: string) => {
      await tagRepository.deleteTag(tag);
      await refreshTags();

      // Refresh current page if it has the tag
      if (currentPage?.tags?.includes(tag)) {
        const updatedPage = await pageRepository.getPageById(currentPage.id);
        if (updatedPage) {
          setCurrentPage(updatedPage);
        }
      }
    },
    [currentPage, refreshTags, setCurrentPage]
  );

  return {
    refreshTags,
    assignTag,
    unassignTag,
    updateTag,
    deleteTag,
    toggleFilter,
    clearFilters,
  };
}
