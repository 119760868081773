import { nanoid } from 'nanoid';
import Dexie, { Table, Transaction } from 'dexie';
import { Collection, Page, PageMetadata, SyncMetadata } from '@/types';

class PageDatabase extends Dexie {
  pages!: Table<Page, string>;
  pagesMetadata!: Table<PageMetadata, string>;
  syncMetadata!: Table<SyncMetadata, string>;
  collections!: Table<Collection, string>;

  constructor() {
    super('PageDatabase');

    this.version(2).stores({
      pages: 'id, title, content, orderNumber, createdAt, updatedAt, archivedAt',
    });

    this.version(3)
      .stores({
        pages: 'id, title, content, orderNumber, createdAt, updatedAt, archivedAt',
        syncMetadata: 'id, lastSyncedAt, deviceId',
      })
      .upgrade(async (trans: Transaction) => {
        // Initialize sync metadata during upgrade
        await trans.table('syncMetadata').add({
          id: 'global',
          lastSyncedAt: 0,
          deviceId: nanoid(),
        });
      });

    this.version(4)
      .stores({
        pages: 'id, title, content, orderNumber, createdAt, updatedAt, archivedAt, *tags',
        syncMetadata: 'id, lastSyncedAt, deviceId',
      })
      .upgrade(async (trans: Transaction) => {
        // Add tags array to existing pages
        const pages = await trans.table('pages').toArray();
        await Promise.all(
          pages.map((page: Page) =>
            trans.table('pages').update(page.id, {
              ...page,
              tags: [], // Initialize empty tags array for existing pages
            })
          )
        );
      });

    // denormalize metadata from pages for faster pageList loading
    this.version(6)
      .stores({
        pages: 'id, title, orderNumber, createdAt, updatedAt, archivedAt, *tags, isPublic, content',
        pagesMetadata: 'id, title, orderNumber, createdAt, updatedAt, archivedAt, *tags, isPublic',
        syncMetadata: 'id, lastSyncedAt, deviceId',
      })
      .upgrade(async (trans: Transaction) => {
        // Migrate existing pages to new structure
        const pages = await trans.table('pages').toArray();

        // Create metadata entries for existing pages
        await Promise.all(
          pages.map(async (page: Page) => {
            const metadata: PageMetadata = {
              id: page.id,
              title: page.title,
              createdAt: page.createdAt,
              updatedAt: page.updatedAt,
              archivedAt: page.archivedAt || 0,
              tags: page.tags || [],
              isPublic: page.isPublic || false,
            };

            await trans.table('pagesMetadata').add(metadata);
          })
        );
      });

    this.version(7).stores({
      collections: 'id, title, createdAt, updatedAt, archivedAt, *tags, isPublic',
      pages:
        'id, title, orderNumber, createdAt, updatedAt, archivedAt, *tags, isPublic, content, collectionId',
      pagesMetadata:
        'id, title, orderNumber, createdAt, updatedAt, archivedAt, *tags, isPublic, collectionId',
      syncMetadata: 'id, lastSyncedAt, deviceId',
    });

    this.version(8).stores({
      collections: 'id, title, createdAt, updatedAt, archivedAt, *tags, isPublic, structure',
      pages: 'id, title, createdAt, updatedAt, archivedAt, *tags, isPublic, content, collectionId',
      pagesMetadata: 'id, title, createdAt, updatedAt, archivedAt, *tags, isPublic, collectionId',
      syncMetadata: 'id, lastSyncedAt, deviceId',
    });

    this.version(10).stores({
      collections: 'id, title, createdAt, updatedAt, archivedAt, *tags, isPublic, *sections',
      pages:
        'id, title, createdAt, updatedAt, archivedAt, *tags, isPublic, content, collectionId, sectionId, orderNumber',
      pagesMetadata:
        'id, title, createdAt, updatedAt, archivedAt, *tags, isPublic, collectionId, sectionId, orderNumber',
      syncMetadata: 'id, lastSyncedAt, deviceId',
    });

    // Hook for automatically generating IDs for new pages
    this.pages.hook('creating', (_, obj) => {
      if (!obj.id) obj.id = nanoid();
      if (!obj.tags) obj.tags = []; // Ensure tags array exists
    });

    this.pages.hook(
      'deleting',
      async (primKey: string, _: Page, transaction: Transaction | null) => {
        if (transaction) {
          // If we're in a transaction, use the transaction object
          await transaction.table('pagesMetadata').delete(primKey);
        } else {
          // If not in a transaction, delete directly
          await this.pagesMetadata.delete(primKey);
        }
      }
    );
  }
}

export const db = new PageDatabase();
