import React, { useState } from 'react';
import { SearchIcon } from 'lucide-react';
import { useSearchPage } from '@/hooks/useSearchPage';
import { CustomModal } from '../../ui/modal';
import { SearchModalContent } from '../../ui/Search';
import ActionLinkButton from '../../ui/ActionLinkButton';
import { useCollectionStore } from '@/stores/useCollectionStore';
import { usePages } from '@/hooks/usePages';

interface SearchButtonProps {
  collectionId: string;
}

export default function CollectionSearchButton({ collectionId }: SearchButtonProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <ActionLinkButton
        Icon={SearchIcon}
        title={'Search'}
        onClick={() => {
          setIsModalOpen(true);
        }}
      />
      <CollectionSearchModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        collectionId={collectionId}
      />
    </>
  );
}

const CollectionSearchModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  collectionId: string;
}> = ({ isOpen, onClose, collectionId }) => {
  const getCurrentCollectionPageMetadata = useCollectionStore(
    state => state.getCurrentCollectionPageMetadata
  );
  const currentCollectionPageMetadata = getCurrentCollectionPageMetadata();
  const { searchPages } = usePages();
  const { searchQuery, searchResults, setSearchQuery, onClickPage } = useSearchPage({
    searchFn: searchPages,
    defaultResults: currentCollectionPageMetadata,
    collectionId,
  });

  const closeModal = () => {
    setSearchQuery('');
    onClose();
  };

  return (
    <CustomModal isOpen={isOpen} onClose={closeModal}>
      <SearchModalContent
        searchQuery={searchQuery}
        searchResults={searchResults}
        onSearchChange={setSearchQuery}
        onClickPage={onClickPage}
      />
    </CustomModal>
  );
};
