import AddPage from './AddPage';
import FilterConfig from './FilterConfig';

const SidebarHeader = () => {
  return (
    <div className="w-full flex items-center justify-between mt-3 mb-2 px-1">
      <h2 className="text-sm font-semibold pl-2">Notes</h2>
      <div className="flex gap-1">
        <FilterConfig />
        <AddPage />
      </div>
    </div>
  );
};

export default SidebarHeader;
