import { memo, useCallback, useEffect } from 'react';
import { PageMetadata } from '@/types';
import { useSaveHook } from '@/hooks/useSaveHook';
import { useNavigate } from 'react-router-dom';
import { usePageStore } from '@/stores/usePageStore';
import { usePages } from '@/hooks/usePages';
import Spinner from '@/components/ui/spinner';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

const HomePage = () => {
  const navigate = useNavigate();
  const pagesMetadata = usePageStore(state => state.pagesMetadata);
  const loading = usePageStore(state => state.loading);
  const { addPage } = usePages();
  const { setCurrentPageId } = usePages();

  useSaveHook();

  const goToPage = useCallback(
    async (metadata: PageMetadata[]) => {
      // Only navigate if we have pages and we're past the initial loading states
      if (metadata.length && !loading) {
        const id = await setCurrentPageId(metadata[0].id);
        if (id) {
          navigate(`/page/${id}`);
        }
      }
    },
    [navigate, setCurrentPageId, loading]
  );

  // Try to navigate when loading is done and we have pages
  useEffect(() => {
    if (!loading && pagesMetadata.length) {
      void goToPage(pagesMetadata);
    }
  }, [goToPage, pagesMetadata, loading]);

  // Show loading spinner for the first 5 seconds
  if (loading) {
    return (
      <div className="w-full h-[75%] flex items-center justify-center">
        <Spinner size={26} />
      </div>
    );
  }

  // Otherwise, show loading spinner while we wait for navigation
  return (
    <div className="w-full h-[75%] flex flex-col items-center justify-center">
      <h3 className="text-2xl mb-2 font-light">¯\_(ツ)_/¯</h3>
      <p className="text-xl font-medium">No pages yet</p>
      <p className="mt-2 mb-4 text-sm">Create a new page to get started</p>
      <Button
        size="lg"
        className="flex items-center gap-2 bg-primary"
        onClick={() => {
          void addPage();
        }}
      >
        <Plus className="mr-2 h-4 w-4" />
        Create New Page
      </Button>
    </div>
  );
};

export default memo(HomePage);
