import { useEffect } from 'react';
import { useTags } from './useTags';
import { useCollections } from './useCollections';
import { usePages } from './usePages';
import { useTagStore } from '../stores/useTagStore';

/**
 * This hook initializes the application by loading necessary data
 * and setting up essential effects
 */
export function useAppInitializer() {
  const { loadCollections } = useCollections();
  const { loadPages } = usePages();
  const { refreshTags } = useTags();

  // Get active filters to watch for changes
  const activeFilters = useTagStore(state => state.activeFilters);

  // Initialize data on mount
  useEffect(() => {
    void refreshTags();
    void loadCollections();
  }, [refreshTags, loadCollections]);

  // Set up filter effect - reload pages when filters change
  useEffect(() => {
    void loadPages();
  }, [activeFilters, loadPages]);

  return null;
}
