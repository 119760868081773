import { useEffect, useRef } from 'react';
import { SearchIcon, XIcon } from 'lucide-react';
import { useMediaQuery } from 'usehooks-ts';

import { PageMetadata } from '@/types';

import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

interface SearchModalContentProps {
  searchQuery: string;
  searchResults: PageMetadata[];
  onSearchChange: (query: string) => void;
  onClickPage: (pageId: string, pageCollectionId?: string) => void;
}

export const SearchModalContent: React.FC<SearchModalContentProps> = ({
  searchQuery,
  searchResults,
  onSearchChange,
  onClickPage,
}) => {
  return (
    <>
      <SearchBox searchQuery={searchQuery} setSearchQuery={onSearchChange} />
      <div className="px-3 py-2 max-h-[300px] overflow-y-auto">
        <h4 className="font-semibold px-2 mb-2">Notes</h4>
        {!searchResults.length && (
          <div className="flex items-center p-2 rounded mb-4">
            <span className="mr-2">{searchQuery ? '😟' : '🔍'}</span>
            {searchQuery ? 'No notes found...' : 'Type to search..'}
          </div>
        )}
        {searchResults.map((doc, index) => (
          <div
            key={index}
            className="flex items-center p-2 cursor-pointer hover:bg-muted hover:text-foreground rounded"
            onClick={() => {
              onClickPage(doc.id, doc.collectionId);
            }}
          >
            <span className="mr-2">📄</span>
            {doc.title}
          </div>
        ))}
      </div>
    </>
  );
};

export const SearchBox = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (isMobile) return;
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="flex items-center w-full border-b-2">
      <div className="flex w-full items-center px-3 p-2">
        <SearchIcon className="h-6 w-6" />
        <Input
          ref={inputRef}
          type="text"
          placeholder="Search notes by title..."
          className="bg-secondary border-none focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-0"
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value);
          }}
        />
      </div>
      {searchQuery && (
        <Button
          variant="ghost"
          size="icon"
          className="p-0 m-0 opacity-40 hover:bg-transparent"
          onClick={() => {
            setSearchQuery('');
          }}
        >
          <XIcon className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};
