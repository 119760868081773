import { create } from 'zustand';

type TagState = {
  tags: string[];
  activeFilters: string[];

  // Simple actions (setters)
  setTags: (tags: string[]) => void;
  addActiveFilter: (tag: string) => void;
  removeActiveFilter: (tag: string) => void;
  toggleFilter: (tag: string) => void;
  clearFilters: () => void;
};

export const useTagStore = create<TagState>(set => ({
  // Initial state
  tags: [],
  activeFilters: [],

  // Simple setters
  setTags: tags => {
    set({ tags });
  },

  addActiveFilter: tag => {
    set(state => ({
      activeFilters: state.activeFilters.includes(tag)
        ? state.activeFilters
        : [...state.activeFilters, tag],
    }));
  },

  removeActiveFilter: tag => {
    set(state => ({
      activeFilters: state.activeFilters.filter(t => t !== tag),
    }));
  },

  toggleFilter: tag => {
    set(state => ({
      activeFilters: state.activeFilters.includes(tag)
        ? state.activeFilters.filter(t => t !== tag)
        : [...state.activeFilters, tag],
    }));
  },

  clearFilters: () => {
    set({ activeFilters: [] });
  },
}));
