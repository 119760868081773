import { FolderOpen, MoreHorizontal } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Collection } from '@/types';
import { formatTimeAgo } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';
import { useCollections } from '@/hooks/useCollections';

const CollectionCard = ({ collection }: { collection: Collection }) => {
  const navigate = useNavigate();
  const { archiveCollection } = useCollections();

  const goToCollection = () => {
    navigate(`/collection/${collection.id}`);
  };

  return (
    <Card
      key={collection.id}
      onClick={goToCollection}
      className="relative bg-secondary hover:shadow-md transition-all cursor-pointer overflow-hidden"
    >
      {collection.isSample && (
        <div className="absolute rotate-45 bg-orange-500 text-white text-xs px-8 right-[-35px] top-[8px]">
          Sample
        </div>
      )}
      <CardHeader className="flex flex-row items-center justify-between p-0">
        <CardTitle className="flex items-center gap-2 px-4 py-4">
          <FolderOpen className="h-5 w-5 text-muted-foreground" />
          <span className="text-lg font-medium max-w-[160px] truncate">{collection.title}</span>
        </CardTitle>
      </CardHeader>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0 absolute top-0 right-0 mx-2 mt-1">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={e => {
              void archiveCollection(collection);
              e.stopPropagation();
            }}
            className="text-red-600 hover:!text-red-600 cursor-pointer"
          >
            Archive
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <CardContent className="p-4 pt-0">
        <div className="flex justify-between text-sm text-muted-foreground">
          <span>Updated {formatTimeAgo(collection.updatedAt)}</span>
        </div>
      </CardContent>
    </Card>
  );
};

export default CollectionCard;
