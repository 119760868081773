import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Archive, Clock, Ellipsis } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { formatTimeAgo } from '@/lib/utils';
import { ConfirmationModal, PublishToggle } from './PublishToggle';
import { usePageStore } from '@/stores/usePageStore';
import { usePages } from '@/hooks/usePages';

export function PageActions() {
  const currentPage = usePageStore(state => state.currentPage);
  const { archivePage } = usePages();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const onArchive = useCallback(async () => {
    if (!currentPage) {
      return;
    }
    await archivePage(currentPage.id, currentPage.collectionId);
    navigate(currentPage.collectionId ? `/collection/${currentPage.collectionId}` : '/');
  }, [archivePage, currentPage, navigate]);

  if (!currentPage) {
    return null;
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="sm" className="h-8 w-8 rounded-full p-0">
            <Ellipsis />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[200px]">
          <DropdownMenuLabel className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <Clock className="h-4 w-4 text-muted-foreground" />
              <span className="text-sm font-normal text-muted-foreground">
                Added{'\n'} {formatTimeAgo(currentPage.createdAt)}
              </span>
            </div>
            <span className="text-sm truncate font-normal px-1"></span>
          </DropdownMenuLabel>
          <PublishToggle setIsOpen={setIsOpen} />
          <DropdownMenuItem
            className="text-red-600 items-center gap-2 cursor-pointer"
            onClick={onArchive}
          >
            <Archive className="h-4 w-4" />
            <span>Archive</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmationModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
}
