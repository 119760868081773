import { useEffect } from 'react';
import { PencilLine, PencilOff } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { usePageStore } from '@/stores/usePageStore';

export function EditableToggle() {
  const editable = usePageStore(state => state.editable);
  const setEditable = usePageStore(state => state.setEditable);

  useEffect(() => {
    const cachedCanEdit = localStorage.getItem('editable');
    const canEdit = cachedCanEdit ? localStorage.getItem('editable') === 'true' : true;
    setEditable(canEdit);
  }, [setEditable]);

  return (
    <Button
      onClick={() => {
        setEditable(!editable);
      }}
      variant={'ghost'}
      className="p-2 px-2.5 rounded-full"
    >
      <Tooltip>
        <TooltipTrigger asChild>
          {editable ? (
            <PencilLine className={'h-[1.1rem] w-[1.1rem]'} />
          ) : (
            <PencilOff className="h-[1.1rem] w-[1.1rem]" />
          )}
        </TooltipTrigger>
        <TooltipContent>
          <span>{editable ? 'Disable editing' : 'Enable editing'}</span>
        </TooltipContent>
      </Tooltip>
    </Button>
  );
}
