import { useCallback } from 'react';
import { isEqual } from 'lodash-es';

import { Collection, DisplayedCollectionSection, PageMetadata } from '@/types';
import collectionRepository from '@/services/database/indexDB/CollectionRepository';
import pageRepository from '@/services/database/indexDB/PageRepository';
import { usePageStore } from '@/stores/usePageStore';
import { useCollectionStore } from '@/stores/useCollectionStore';

export function useCollections() {
  // Get state and setters from stores
  const {
    collections,
    setCollections,
    currentCollection,
    setCurrentCollection,
    collectionStructure,
    setCollectionStructure,
    updateCollection: updateCollectionInStore,
  } = useCollectionStore();

  const { setLoading } = usePageStore();

  // Load all collections
  const loadCollections = useCallback(async () => {
    const collections = await collectionRepository.getCollections();
    setCollections(collections);
  }, [setCollections]);

  // Add a new collection
  const addCollection = useCallback(async () => {
    const coll = await collectionRepository.addCollection();
    setCollections([coll, ...collections]);
    return coll;
  }, [collections, setCollections]);

  // Archive a collection
  const archiveCollection = useCallback(
    async (coll: Collection) => {
      await collectionRepository.archiveCollectionData(coll);
      await loadCollections();
    },
    [loadCollections]
  );

  // Search collections
  const searchCollections = useCallback(
    async (query: string) => {
      const results = await collectionRepository.searchCollections(query);
      setCollections(results);
      return results;
    },
    [setCollections]
  );

  // Update a collection
  const updateCollection = useCallback(
    async (id: string, collection: Partial<Collection>) => {
      await collectionRepository.updateCollection(id, collection);
      updateCollectionInStore(id, collection);
    },
    [updateCollectionInStore]
  );

  const loadCollectionStructure = useCallback(
    async (collectionId?: string) => {
      if (!collectionId) {
        setCollectionStructure([]);
        return [];
      }

      const collection = await collectionRepository.getCollectionById(collectionId);
      if (!collection) {
        setCollectionStructure([]);
        return [];
      }

      const collectionPageMetadata =
        await collectionRepository.getCollectionPageMetadata(collectionId);

      const structure: DisplayedCollectionSection[] = (collection.sections || [])
        .filter(s => !s.archivedAt)
        .map(section => ({
          section: {
            ...section,
            pages: collectionPageMetadata.filter(p => p.sectionId === section.id),
          },
        }));

      setCollectionStructure(structure);
      return structure;
    },
    [setCollectionStructure]
  );

  // Set the current collection
  const setCollection = useCallback(
    async (collectionId: string | null) => {
      if (!collectionId) {
        setCurrentCollection(null);
        return null;
      }

      setLoading(true);

      const collection = await collectionRepository.getCollectionById(collectionId);
      if (!collection) {
        setLoading(false);
        return null;
      }

      setCurrentCollection(collection);
      const structure = await loadCollectionStructure(collection.id);
      setLoading(false);
      return { ...collection, structure };
    },
    [loadCollectionStructure, setCurrentCollection, setLoading]
  );

  // Update the structure of a collection
  const updateStructureItem = useCallback(
    async (newStructure: DisplayedCollectionSection[]) => {
      if (!currentCollection) return;

      const newSections = newStructure.map(s => s.section);
      const oldSections = currentCollection.sections || [];
      const hasSectionsChanged = !isEqual(newSections, oldSections);

      if (hasSectionsChanged) {
        await collectionRepository.updateCollection(currentCollection.id, {
          sections: [
            ...newSections,
            ...oldSections
              .filter(os => !newSections.map(nS => nS.id).includes(os.id))
              .map(os => ({ ...os, archivedAt: new Date().getTime() })),
          ],
        });

        setCurrentCollection({
          ...currentCollection,
          sections: newSections,
        });
      }

      setCollectionStructure(newStructure);
      const allPages = newStructure.map(s => s.section.pages).flat();
      const pagesMap = new Map<string, PageMetadata>();
      collectionStructure.forEach(s => {
        s.section.pages.forEach(page => pagesMap.set(page.id, page));
      });

      // Update page metadata changes
      for (const page of allPages) {
        const oldPage = pagesMap.get(page.id);
        if (isEqual(oldPage, page)) continue;
        await pageRepository.updatePage(page.id, {
          sectionId: page.sectionId,
          orderNumber: page.orderNumber,
        });
      }

      void loadCollectionStructure(currentCollection.id);
    },
    [
      collectionStructure,
      currentCollection,
      loadCollectionStructure,
      setCollectionStructure,
      setCurrentCollection,
    ]
  );

  // Archive a section of a collection
  const archiveCollectionSection = useCallback(
    async (sectionId: string) => {
      if (!currentCollection) return;

      await collectionRepository.archiveCollectionData(currentCollection, sectionId);
      await loadCollectionStructure(currentCollection.id);
    },
    [currentCollection, loadCollectionStructure]
  );

  return {
    loadCollections,
    addCollection,
    archiveCollection,
    searchCollections,
    updateCollection,
    setCollection,
    loadCollectionStructure,
    updateStructureItem,
    archiveCollectionSection,
  };
}
