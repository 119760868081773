import React, { useMemo } from 'react';
import '@blocknote/core/fonts/inter.css';
import '@blocknote/mantine/style.css';
import { Block } from '@blocknote/core';
import { BlockNoteView } from '@blocknote/mantine';
import { useTheme } from '@/providers/ThemeProvider';
import { getBlockNotesStyles, getBlockNotesContent, parseBase64 } from '@/styles/blocknotesStyles';
import {
  DragHandleButton,
  SideMenu,
  SideMenuController,
  useCreateBlockNote,
} from '@blocknote/react';
import { usePageStore } from '@/stores/usePageStore';
interface EditorProps {
  onChange: (value: string) => void;
  initialContent?: string;
  enableEditing?: boolean;
}

const Editor: React.FC<EditorProps> = ({ onChange, initialContent, enableEditing = true }) => {
  const { theme } = useTheme();
  const editable = usePageStore(state => state.editable);

  const customTheme = useMemo(() => getBlockNotesStyles(theme), [theme]);
  const handleUpload = async (file: File) => parseBase64(file);

  const editor = useCreateBlockNote({
    initialContent: getBlockNotesContent(initialContent),
    defaultStyles: true,
    trailingBlock: true,
    animations: true,
    uploadFile: handleUpload,
  });

  const handleEditorChange = (blocks: Block[]) => {
    onChange(JSON.stringify(blocks, null, 2));
  };

  return (
    <div className="w-screen md:w-auto overflow-x-clip">
      <BlockNoteView
        sideMenu={false}
        className="pb-20"
        editor={editor}
        theme={customTheme}
        onChange={() => {
          handleEditorChange(editor.document);
        }}
        editable={enableEditing && editable}
        spellCheck={false}
      >
        <SideMenuController
          sideMenu={props => (
            <SideMenu {...props}>
              <DragHandleButton {...props} />
            </SideMenu>
          )}
        />
      </BlockNoteView>
    </div>
  );
};

export default Editor;
