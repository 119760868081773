import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Trash, Undo } from 'lucide-react';
import { usePageStore } from '@/stores/usePageStore';
import { usePages } from '@/hooks/usePages';

const ArchivedIndicator = () => {
  const navigate = useNavigate();

  const currentPage = usePageStore(state => state.currentPage);
  const { unarchivePage, deletePage } = usePages();

  const archivedAt = currentPage?.archivedAt
    ? format(new Date(currentPage.archivedAt), 'MMM do yyyy, HH:mm')
    : null;

  const permanentlyDeletePage = async (id: string) => {
    await deletePage(id);
    navigate(`/`);
  };

  if (!currentPage || !archivedAt) return null;

  return (
    <Alert className="bg-destructive text-destructive-foreground rounded-none border-none p-2 mt-[9px] mb-8 text-white">
      <AlertDescription className="flex w-100 items-center">
        <div className="flex-grow text-center text-md">This notes were trashed on {archivedAt}</div>
        <div className="flex space-x-2">
          <Button
            variant={'outline'}
            size={'sm'}
            className="border-destructive-foreground bg-transparent h-6 border-white"
            onClick={() => void unarchivePage(currentPage.id)}
          >
            <Undo className="mr-2 h-4 w-4" />
            Restore
          </Button>
          <Button
            variant={'outline'}
            size={'sm'}
            className="border-destructive-foreground bg-transparent h-6 border-white"
            onClick={() => void permanentlyDeletePage(currentPage.id)}
          >
            <Trash className="mr-2 h-4 w-4" />
            Permanently Delete
          </Button>
        </div>
      </AlertDescription>
    </Alert>
  );
};

export default ArchivedIndicator;
