import React from 'react';
import { Card } from '@/components/ui/card';
import { FolderOpen, FolderTree, GraduationCap, Network } from 'lucide-react';

const CollectionBanner = () => {
  return (
    <Card className="mx-auto md:max-w-3xl lg:max-w-4xl p-8 mt-2 bg-secondary">
      {/* Header Section */}
      <div className="flex items-center justify-between mb-2">
        <div className="flex gap-4 items-center">
          <FolderOpen className="h-6 w-6 text-secondary-foreground" />
          <h1 className="text-xl font-bold">Collections</h1>
        </div>
      </div>
      <p className="text-muted-foreground text-md">
        Group related notes to stay organized. Perfect for projects, ideas, or any notes that belong
        together.
      </p>
      <div className="pt-6 pb-2 text-center">
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-left">
            <FeatureCard
              title="Organize"
              icon={<FolderTree className="h-8 w-8 text-muted-foreground" />}
              description="Group related notes together in meaningful ways"
            />
            <FeatureCard
              title="Connect Ideas"
              icon={<Network className="h-8 w-8 text-muted-foreground" />}
              description="Develop related thoughts and concepts together"
            />
            <FeatureCard
              title="Learn Better"
              icon={<GraduationCap className="h-8 w-8 text-muted-foreground" />}
              description="Build collections to grow your understanding on any topic"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CollectionBanner;

const FeatureCard = ({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
}) => (
  <div className="flex gap-3 py-4 rounded-lg">
    {icon}
    <div>
      <h3 className="font-semibold mb-2">{title}</h3>
      <p className="text-muted-foreground text-sm">{description}</p>
    </div>
  </div>
);
