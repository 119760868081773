import { db } from './database';
import { BaseRepository } from './BaseRepository';
import pageRepository from './PageRepository';

export class TagRepository extends BaseRepository {
  async assignTag(pageId: string, tagName: string) {
    const normalizedTag = tagName.trim().toLowerCase();

    // Update page's tags
    const page = await pageRepository.getPageById(pageId);
    if (page) {
      const tags = new Set(page.tags || []);
      tags.add(normalizedTag);
      await pageRepository.updatePage(pageId, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async unassignTag(pageId: string, tagName: string) {
    const normalizedTag = tagName.trim().toLowerCase();

    // Update page's tags
    const page = await pageRepository.getPageById(pageId);
    if (page && page.tags) {
      const tags = new Set(page.tags);
      tags.delete(normalizedTag);
      await pageRepository.updatePage(pageId, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async editTag(oldName: string, newName: string) {
    const normalizedOld = oldName.trim().toLowerCase();
    const normalizedNew = newName.trim().toLowerCase();

    if (normalizedOld === normalizedNew) return;

    // Update all pages that have this tag
    const pages = await db.pages.where('tags').anyOf([normalizedOld]).toArray();
    for (const page of pages) {
      const tags = new Set(page.tags || []);
      tags.delete(normalizedOld);
      tags.add(normalizedNew);
      await pageRepository.updatePage(page.id, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async deleteTag(tagName: string) {
    const normalizedTag = tagName.trim().toLowerCase();

    // Remove tag from all pages
    const pages = await db.pages.where('tags').anyOf([normalizedTag]).toArray();
    for (const page of pages) {
      const tags = new Set(page.tags || []);
      tags.delete(normalizedTag);
      await pageRepository.updatePage(page.id, {
        tags: Array.from(tags),
        updatedAt: this.now,
      });
    }
  }

  async getTags() {
    const pages = await db.pages.toArray();
    const tagSet = new Set(pages.map(p => p.tags || []).flat());
    const orderedTags = [...tagSet].sort((a, b) => a.localeCompare(b));
    return [...orderedTags];
  }
}

export default new TagRepository();
