import { memo, ReactNode, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CollectionSidebar from './Sidebar';
import { useKeyboardCommands } from '@/hooks/useKeyboardCommands';
import { useCollections } from '@/hooks/useCollections';
import { useCollectionStore } from '@/stores/useCollectionStore';
import { usePages } from '@/hooks/usePages';

type CollectionLayoutProps = {
  children: ReactNode;
  Menu?: ReactNode;
};

const CollectionLayout = ({ children, Menu }: CollectionLayoutProps) => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const { pageId, collectionId } = useParams<{ pageId: string; collectionId: string }>();
  const currentCollection = useCollectionStore(state => state.currentCollection);
  const { setCollection } = useCollections();
  const { setCurrentPageId } = usePages();
  useKeyboardCommands();

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchCollection = async () => {
      if (!collectionId) {
        navigate(`/collections`);
        return;
      }

      const collection = await setCollection(collectionId);
      if (!collection) {
        await setCollection(null);
        navigate(`/collections`);
        void setCurrentPageId(null);
        return;
      }
      if (pageId && collectionId) {
        navigate(`/collection/${collectionId}/page/${pageId}`);
        return;
      }
      const firstPage =
        collection.structure.length &&
        collection.structure[0].section.pages.length &&
        collection.structure[0].section.pages[0];
      if (firstPage && !pageId) {
        navigate(`/collection/${collectionId}/page/${firstPage.id}`);
      }
    };
    void fetchCollection();
  }, [collectionId, navigate, pageId, setCollection, setCurrentPageId]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          scrollToTop();
        }, 100);
      });
    }
  }, [pageId, collectionId]);

  if (!currentCollection) {
    return null;
  }

  return (
    <div className="flex h-screen">
      <CollectionSidebar
        collection={currentCollection}
        onBackClick={() => {
          navigate('/collections');
        }}
      />
      <main ref={containerRef} className="flex-1 overflow-y-auto relative overflow-x-hidden">
        {Menu}
        {children}
      </main>
    </div>
  );
};

export default memo(CollectionLayout);
