import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Editor from '@/components/Editor';
import PageTitle from '@/components/PageTitle';
import { useSaveHook } from '@/hooks/useSaveHook';
import { useSyncContext } from '@/providers/SyncProvider';
import { usePageStore } from '@/stores/usePageStore';
import { usePages } from '@/hooks/usePages';
import { debounce } from 'lodash-es';
import { Page } from '@/types';
import Spinner from '@/components/ui/spinner';

const CollectionDetailPage = () => {
  const [showLoader] = useState<boolean>(false);

  const { pageId } = useParams<{ pageId: string }>();
  const editable = usePageStore(state => state.editable);
  const currentPage = usePageStore(state => state.currentPage);
  const { setCurrentPageId, updateCurrentPageContent, updatePageTitle } = usePages();
  const { lastSyncedAt } = useSyncContext();

  useSaveHook();

  const debouncedSetCurrentPageId = useCallback(
    (pageId: string) => {
      void setCurrentPageId(pageId);
    },
    [setCurrentPageId]
  );

  useEffect(() => {
    if (pageId) {
      debouncedSetCurrentPageId(pageId);
    }
  }, [debouncedSetCurrentPageId, pageId, setCurrentPageId]);

  const debouncedUpdateContent = useDebounce<string>(updateCurrentPageContent, 300);
  const debouncedUpdateTitle = useDebounce<string>(
    title => void updatePageTitle((currentPage as Page).id, title),
    300
  );

  if (showLoader) {
    return (
      <div className="w-full h-[75%] flex items-center justify-center">
        <Spinner size={26} />
      </div>
    );
  }

  if (!currentPage) {
    return null;
  }

  return (
    <div>
      <div className="overflow-y-auto overflow-x-hidden">
        <div className="mx-auto md:max-w-3xl lg:max-w-4xl">
          <PageTitle
            key={currentPage.id}
            title={currentPage.title || 'Untitled'}
            editable={editable}
            onChange={debouncedUpdateTitle}
            showTag={false}
          />
          <Editor
            key={`${currentPage.id}_${String(lastSyncedAt)}`}
            initialContent={currentPage.content}
            onChange={debouncedUpdateContent}
          />
        </div>
      </div>
    </div>
  );
};

export default CollectionDetailPage;

const useDebounce = <T,>(updateFn: (...args: T[]) => void, delay = 300) => {
  return useMemo(
    () =>
      debounce((...args: T[]) => {
        updateFn(...args);
      }, delay),
    [updateFn, delay]
  );
};
