import { CloudOff, RefreshCw, Loader } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useSyncContext } from '@/providers/SyncProvider';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';

// Simple spinner component with consistent dimensions
const Spinner = () => {
  return (
    <div className="flex items-center justify-center h-8 w-8">
      <Loader className="h-4 w-4 animate-spin text-muted-foreground" />
    </div>
  );
};

export function SyncStatus() {
  const { syncLoading, user, signIn, handleSync } = useSyncContext();

  // Consistent container for all states
  const containerClasses = 'h-8 w-8 flex items-center justify-center';

  if (syncLoading) {
    return (
      <div className={containerClasses}>
        <Spinner />
      </div>
    );
  }

  if (!user) {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="ghost" size="sm" className="h-8 w-8 rounded-full p-0" onClick={signIn}>
            <CloudOff className="h-5 w-5 text-muted-foreground" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>You're offline. SignIn?</p>
        </TooltipContent>
      </Tooltip>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="ghost" size="sm" className="h-8 w-8 rounded-full p-0" onClick={handleSync}>
          <RefreshCw className="h-4 w-4 text-muted-foreground" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <p>Sync/Backup Online</p>
      </TooltipContent>
    </Tooltip>
  );
}
