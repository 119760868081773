import { nanoid } from 'nanoid';
import { Collection, Section } from '@/types';
import { db } from './database';
import { BaseRepository } from './BaseRepository';
import pageRepository from './PageRepository';

export class CollectionRepository extends BaseRepository {
  async getCollectionsSince(syncTime: number | null) {
    const collections = await db.collections
      .where('updatedAt')
      .above(syncTime || 0)
      .toArray();

    return collections.map(n => ({
      ...n,
      createdAt: ((n.createdAt / 1000) | 0) * 1000,
      updatedAt: ((n.updatedAt / 1000) | 0) * 1000,
      archivedAt: n.archivedAt ? ((n.archivedAt / 1000) | 0) * 1000 : 0,
    }));
  }

  async getCollectionById(id: string): Promise<Collection | undefined> {
    return db.collections.get(id);
  }

  async getCollections(): Promise<Collection[]> {
    return db.collections.where('archivedAt').equals(0).reverse().sortBy('updatedAt');
  }

  async addCollection(): Promise<Collection> {
    const collectionId = nanoid();
    const sectionId = nanoid();
    const sections = [{ id: sectionId, title: 'Section 1', orderNumber: 0 }] as Section[];
    const newCollection: Collection = {
      id: collectionId,
      title: 'Untitled Collection',
      createdAt: this.now,
      updatedAt: this.now,
      archivedAt: 0,
      sections,
    };
    await db.collections.add(newCollection);
    await pageRepository.addPage({ collectionId, sectionId, orderNumber: 0 });
    return newCollection;
  }

  async archiveCollectionData(collection: Collection, sectionId?: string) {
    const pages = await this.getCollectionPageMetadata(collection.id, sectionId);
    const updates = pages.map(page => pageRepository.archivePage(page.id));
    await Promise.all(updates);
    const collectionUpdates: Partial<Collection> = { updatedAt: this.now };
    if (!sectionId) {
      collectionUpdates.archivedAt = this.now;
    }
    await db.collections.update(collection.id, collectionUpdates);
  }

  async getCollectionPageMetadata(collectionId: string, sectionId?: string) {
    const collection = await this.getCollectionById(collectionId);
    if (!collection) return [];
    const query = db.pagesMetadata
      .where('collectionId')
      .equals(collectionId)
      .and(page => !page.archivedAt);

    if (sectionId) {
      query.and(page => page.sectionId === sectionId);
    }

    return query.sortBy('orderNumber');
  }

  searchCollections(searchQuery: string) {
    return db.collections
      .where('archivedAt')
      .equals(0)
      .and(collection => collection.title.toLowerCase().includes(searchQuery.toLowerCase()))
      .reverse()
      .sortBy('updatedAt');
  }

  updateCollection(collectionId: string, collection: Partial<Collection>) {
    return db.collections.update(collectionId, { ...collection, updatedAt: this.now });
  }

  bulkUpsertCollections(collections: Collection[]) {
    return db.collections.bulkPut(collections);
  }

  bulkDeleteCollections(collections: Collection[]) {
    return db.collections.bulkDelete(collections.map(c => c.id));
  }
}

export default new CollectionRepository();
